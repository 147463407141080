import React from "react";
import styled from "styled-components";
import Servicecards from "../ourservices/servicecards";
import Agri1 from "../../assets/images/ourservices/ind1.png";
import Agri2 from "../../assets/images/ourservices/ind2.png";



const ServiceHeading = styled.p`
text-align: left;
    font: normal normal normal 20px/50px Poppins;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0.3px;
    color: #545BA1;
    text-transform: capitalize;
    font-weight: 500;
    opacity: 1;
    margin-left: 10%;
    margin-top: 0%;
    width: 60%;

    @media screen and (max-width:650px){
    text-align: left;
    font: normal normal normal 13px/55px Poppins;
    margin-left: 5%;
    margin-top: 5%;
    line-height: 20px;
    width: 90%;
    }


    
`





const Industries = () => {
    return(
       <>
        <ServiceHeading>Industries And Others Etc.,</ServiceHeading>
        <Servicecards image1 = {Agri1} image2 = {Agri2} />
        
       </>
    );
};

export default Industries;