import React from "react";
import styled from "styled-components";
// import bgIcon from "../assets/images/background/working.png";
import { Title } from "../styledComponent/styled";
import logo from "../assets/images/logo/dezal-written-logo.svg";
import bgIcon from "../assets/images/background/workingdesktop.svg";
import bgIconmobile from "../assets/images/background/workingmobile.svg";
import bgIcontab from "../assets/images/background/tab.svg";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 5% 0%;
  font-family: Poppins;
`;
const Image = styled.img`
  width: 65%;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const Imagetab = styled.img`
  width: 90%;
  display: none;

  @media screen and (max-width: 850px) {
    display: block;
  }

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

const Imagemobile = styled.img`
  width: 90%;
  display: none;

  @media screen and (max-width: 650px) {
    display: block;
  }
`;

const Dezal = styled.span`
  background-image: linear-gradient(90deg, #ff8934, #ff6b00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const NewTitle = styled(Title)`
  margin-top: 0px;
  margin-bottom: 34px;
`;

const Working = () => {
  return (
    <>
      <Container>
        <NewTitle>
          How <img src={logo} /> Works
        </NewTitle>

        <Image src={bgIcon} />
        <Imagemobile src={bgIconmobile} />
        <Imagetab src={bgIcontab} />
      </Container>
    </>
  );
};

export default Working;
