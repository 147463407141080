import React from "react";
import Terms from "../../components/terms/terms";

function terms() {
  return (
    <>
      <Terms
        Heading="Terms of Services"
        Title1="  here in after referred as “The Company” or “Company”is to provide and operate Mobile Fuel Dispenserfor the purpose of Door Delivery of the petroleum productsto Customers using a technology platform (A mobile or WebApplication) with systems and standard operating procedures in place. The Companyis authorized to offer Door Deliveryof Petroleum Productsand allied  servicesfollowing the guidelines prescribed  by PESO &  relevant  statutory bodies. The Company’smission is to provide Planning, Control & Convenience to itscustomers and enhance customer experience by optimization of processes, efforts and resources. The customer can schedule products as per his requirementthrough mobile or web application, monitor & control the delivery process, pay online, get the  invoices  and  MIS  reports  on  a  click.  The  services  will  help customer  with organized structure of fuelprocurement anddelivery process, avoid dependency for transport  &  handling,  eliminate  fuel  pilferage  &  wastage,  save  fuel  &  money  and reducein Carbon Emissions."
        Con1='This  Agreement is  a  legal  document  between  User  (as  defined  below)  andThe Companygoverning the User’s relationship  with the  Company. The  Companyand User are hereinafter individually referred as "Party" and collectively referred to as "Parties".The Online Registration Form, Privacy Policy, any relevant Policies by the Companyand  the  Terms  and  Conditions  mentioned  herein  shall  be  collectively termed as an "Agreement".'
        Introhead="Definitions:"
        span="M/s GRIT DEZAL ECOMM PRIVATE LIMITED"
        Introhead1="User:"
        con2="defined  by  words  including  you,  yours,  consumer, customer, individual, person,  entity,  party,  user,  end-user, client,  purchaser,  principal  all  collectively and/or separately intended to mean the “user” who uses the Services and who is the  recipient  of  the petroleum  products  (esp.  HSD  or  Diesel,  Lubricants  etc.,)procured by using  the  service  as  described  and  provided  by The  Company,  and specifically  excluding  an  individual  or the  Companythat  has  been  barred  from procuring Fuelby an Government Agency or OMC."
        Introhead2="Petroleum  Products:"
        con3="Products  that  come from refining  of  Petroleum  are  called  as Petroleum  Products. It includes High  Speed  Diesel  (HSD)  or  Diesel,  Lubricantsand other relatedfuels, which are permitted to sell through Mobile Dispenser by PESO.Herein after referred to as “Petroleum Products” or “Fuel”or “Product/s”."
        Introhead3="Other information:"
        Introhead34="OMC : "
        con4="OMC means OilMarketingCompany. IndianOil  Corporation  Ltd  (IOCL),  Hindustan Petroleum  Corporation  Limited  (HPCL),  Bharat  Petroleum  Corporation  Limited (BPCL), EssarEnergy or NayaraEnergy, Shell, Relianceand its JVs or Subsidiaries and anyCompany(both Public and Private)havingitsretail presence and authorized for marketing and selling of Petroleum products."
        Introhead35="Dealer : "
        con5="Dealer means the person operating a particular Retail Outlet."
        Introhead36="Operator : "
        con6="Operator means the personor entityfacilitating for the delivery process, appointed by The Company."
        Introhead37="Partner : "
        con7="Partner means  the  person  or  entity  in  tie-up  with the  Company,  appointed  as operator by the Company& working for the Company."
        Introhead38="HSD : "
        con8="HSD means High Speed Diesel or Diesel confirming IS specification IS 1460-2005."
        Introhead39="PESO : "
        con9="PESO means Petroleum and Explosives Safety Organization, Nagpur, governing  the Petroleum Act and Rules framed thereunder as amended from time to time."
        Introhead40="The Company : "
        con10="The Company defined by words including our/we/ours and its parents, subsidiaries, affiliates,  employees, operators  collectively  and/or  separately  intended  to  mean, “The  Company” which was registered in the name and style of M/s GRIT DEZAL ECOMM PRIVATE LIMITED and having its registered address at H.No. 31-3-1813, Plot No.  24/A,  PARIMALA  Colony,  Veera  NGOs  Colony,  Warangal,  Telangana,  India, 506370."
        Introhead41="Services: "
        con11="Services defined   by   word   including   Fuel   procurement   services,   commission agent/representativeservices, On    demand    Vendor    Services, commercial agent/representativeservices,    delivery    services,    account    management    & maintenance servicesand any all of the preceding with The Companyacting as the user’s  authorized vendor/agent/representativeduring    the    purchaseand transportation, logistics and Fuel transferring services bythe company or operator, any and all in all cases collectively and/or separately to mean the “Services”."
        con12="The word “Services” is meant to include “Application” and/or actions emerge from accessing/using the Application, hence the word “Services” shall be read as Services obtained via accessing/using of Application and actions emerge from accessing/using the Application.Service and/or Application,wherever applicable, are complementary to each other."
        Introhead42="Application: "
        con13="Application defined by words including  applications,website,app,phone,laptop,relevant content all collectively and/or separately intended to mean the “Application”"
        Introhead43="Vendor/Agent/representative:"
        con14="Vendor/Agent/representative defined by words including commission agent/representative, commercial agent/representative,Fuel procurement agent/representative, Account Manager,authorized representative of user, intermediary, collectively and/or separately intended to mean the “Vendor/Agent/representative”."
        Introhead44="Container:"
        con15="Container: defined as any receptacle designed to store Fuel, securely clamped and mounted on a vehicle approved by PESO."
        Introhead45="“Mobile Petrol Pump” /“Fuel Bowser”/“Bowser” / “Mobile Fuel Dispenser”/ “Mobile Dispenser”"
        con16="“Mobile Petrol Pump” /“Fuel Bowser”/“Bowser” / “Mobile Fuel Dispenser”/ “Mobile Dispenser” shall  mean  a  vehicle  which  is  mounted  with  a  fuel  tank  and  a  fuel dispensing apparatus, in conformity to the Guidelines issued by the PESO and  other  statutory bodies, that areowned and/or Leased and operated by the Companyor Operator or Partner."
        Introhead46="“Platform”/“Technology Platform”"
        con17="“Platform”/“Technology Platform” is a software application created,developed, maintained, owned and updated by the Companyfrom time to time and is hostedon a server designated by the Company."
        con18="In these terms, the words “including” and “include” mean “including but not limited to” "
        Introhead4="1. TERMS OF USE"
        con19='This ‘Terms of Use’ is formulated and published pursuant to the provisions of Rule 3 (1)  of  the  Information Technology  (Intermediaries  Guidelines)  Rules,  2011  that requires the publication of Rules and Regulations, Privacy Policy and Terms of Use for access or usage of a website. This Terms of Service Agreement (the"Agreement") governs your use of this website “www.dezal.in”which isowned and operated by the Company.'
        con20="Any person accessing/using the Website is addressed herein as“user” or simply as “you”. The user’s’ access and use of the services provided by this Website (the “Services”) hereby constitutes and deems the user’s’ agreement to be bound by these Terms of the Agreement"
        con21="By using this Website the User agrees to these Terms, and designatesthe Companyas its agent/representative/representative acting on hisbehalfand/or recognize the Companyas the process owner, fromwhomto procuretheFuel and to transport it to a location chosen by the User, and thereafter transfer the Fuelinto a container or other place if directed by the User."
        con22="The Company here by declares and the user acknowledges and agrees that, for the purposes of these Terms or otherwise, The Companyis not an oil the Company/ oil marketing the  Company(or  its agent/representative/affiliate/subsidiary  or  in  any way  whatsoever  related  to  any  oil the  Companyor  oil  marketing the  Companyoperating  in  India  or  anywhere  else  In  the  world)  and The  Companydoes  not endorse  in  explicit  or  implied  form,  any  Oil  Marketing The  Companyor  Oil The Companyoperating In India or anywhere in the world."
        con23="The Companyonly providestechnology andlogistical services to its User’sthrough (i) procuring; (ii) transporting; and (iii) delivering the Fuelat a location specified by the User, by accessing/using the application,as described in detail in 'Section 2The services'. In providing the service that it provides, The Companyacts as the user’sagent/representative/representativeto  find  the Fuelbased  on ease  of  access  and distance  to  the  final  delivery  point  and  transport  the Fuelprocured  on  the user’sbehalf, to a location of the user’schoosing and transfer the said Fuelprocured on the user’sbehalf into a container/s of the user’schoosingas per the guidelines by PESO. The Companydoes not indulge in an unauthorized exchange or unauthorized saleor unauthorized purchase or any malpractice."
        con24="The agreement/contract has been entered into specifically between two parties, the user   and The   Company,   at   the user’sinitiation   and   behest,   with   the   user understanding  and  acknowledging  that  all  disclaimers,  conditions  and  terms  of service  including  all  those  stated  or  implied  in  this  document  are non-negotiable, and  further  the  user  acknowledging  and  agreeing  that  the  user  approached The Companywith  an  intention  to  hire The  Company’s services, and in doing so undertakes to have acted independently and without any coercion or compulsion or undo any duress."
        con25="If the user does not agree to these Terms, the user shall not access and/or use the Website  and/or  the  Services.  These  Terms  expressly  supersede  prior  or  other agreements  or  other  arrangements  that  may  exist  between  the user  and The Company.  Notwithstanding  this, The  Companymay  at  any  time  terminate  these Terms or any Services, or cease offering or deny access to the Services or any portion thereof to a User. Supplemental terms may apply to Services and shall be deemed a part of these Terms. Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable Services. The Companymay, at any time, amend these Termswithout any intimation to the User. Amendments will become effective upon their publication on the Website. Please check this location from time to time to access revisions and additions to these Terms. The User is responsible for keeping updated with revised or amended terms from time to time. The User’suse of the Services after the publication of amended terms constitutes and deems the user’sconsent to be bound by the amended Terms. If the user does not agree with the amended Terms, please do not use the Services."
        Introhead5="2. THE SERVICES"
        con26="The Services offered constitute a technology platform/ platformthatenable user’s"
        con27="I. To  designate The  Companyas  an agent/representativeacting  on  the user’sbehalf to procure the Fueland"
        con28="II. To  schedule  transportation  of  the Fuelprocured  on  the user’sbehalf (including  transportation  by  hiring/leasing The  Companyequipment  with operator to transport the Fuelprocured on the user’sbehalf) to a location of the user’schoosing and further transfer Fuelprocured on the user’sbehalf, by hiring/leasing The Company’s equipment with operator into a container of the user’schoosing."
        con29="I And II collectively part of 'Services’. The Companyacts as the user’sagent/representativeto  procure  the Fuelon  the user’sbehalf  from  Authorized Government Agencies or Authorized dealers of OMC’sbased on ease of access and distance to final delivery point."
        con30="Unless otherwise agreed by The Companyin a separate written agreement, the user, the  Services  are  made  available in  full/partly/customized solely for the user’s personal and non-commercialuse. "
        con31="The  Companyprovides  on-demand Fueldelivery  services  as  described  in  these Terms. The user may order The Company’s Services using The Company’s Website / mobile  application.  The  user  may  cancel  orders as  per  the  terms  of  Cancellation Policy  of  the  company. The  Companywill  also  notify  the  user about  the  order confirmation  and  dispatch  of  delivery  vehicle  to  the  to  a  location  specified  by  the user. When The Companynotifies the user thatits delivery truck is on route,or the user  specifies  an  appointment  window  the  user  agrees  that  this  window  is  an estimate  only-The  Companydoes endeavor  to  deliver  the  fuel  withinthe  agreed appointment window but notguarantee delivery at any particular time within such window and does not guarantee that The Company’s vehicle will arrive within the estimatedtime."
        con32="Once The Company’s delivery vehicle arrives onsite, whether within or outside the estimated window, the driver will notify the user via The Company’s application, and The  Company’s driver will  wait  no  more  than  fifteenminutes  for  the  user  to  give access  to  a  container  of  the user’schoosing  for  transferring  the Fuelprocured.  If accessnot  granted  within  suchfifteen  minutesperiod, The  Company’s delivery vehicle will leave the user’s location andthe user will be chargedthe applicable fees irrespective of whether or not the delivery was successful. Due to reasons such as restrictions imposed by applicable law, terrain or building features and limitations (e.g.  overhead  clearance),  or  the  requirements  of  some  private  property  owners, the services may not be available in all locations within a service area. We reserve the right to decline to enter into any area based on ease of access or for any other reasonknown to the company. The Companyshallnot be charged insuch an event and cancellation of such orders will be processed as per the Company’s cancellation policy."
        Introhead6="3. LICENSE"
        con33="Contingent to the user’sagreement to these Terms and stipulations, and deemed agreement in case of use, The Companyhereby grants the user a limited,restricted, non-sub licensable, user irrevocable (during an active order) but revocable by The Company(at any time), non-transferrablelicense to: "
        con34="I. Access and use the Applications on the user’s personal device solely in connection with the use of the Services (see Section 4Use of services)."
        con35="II. Access and use any content, information and related materials that may be made available through the use of Services, in each case for the user’s personal and non-commercial use. (see Section 4Use of Services)."
        con36="Any rights not expressly granted herein are hereby reserved by The Companyand The Company’s licensors."
        Introhead7="3.1 RESTRICTIONS AND CONTINGENCIES OF GRANT OF LICENSE" //subhead1
        con37="The license is granted to user contingent to the following conditions.The user representswarrants and undertakes that the user:"
        con38="I. A Dealer/Agent/representative/Distributor  appointed  byOMCengaged  in marketing  of Petroleum  products  inIndiato  access/use  the  applicationwithout prior notice/approval to/from the company."
        con39="II. An individual  or the  Companybarred  from  procuring  the Fuelby  any Government Agency or OilCompany."
        con40="III. Will not alter, add or remove any copyright, trademark or other proprietary notices from any portion of the Application."
        con41="IV. Will not duplicate, reproduce, modify, prepare derivative works based upon, distribute,   license,   lease,   sell,   resell,   transfer,   publicly   display,   publicly perform, transmit, steam, broadcast or otherwise exploit the Services except as expressly by The Company."
        con42="V. Will not decompile, reverse engineer or disassemble the Application and/or Services"
        con43="VI. Will not link to mirror or frame any portion of the Application and/or Services."
        con44="VII. Will not cause or Launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the Application and/or   Services   or   unduly   burdening   or   hindering   the   operation   and functionally  of  any  aspect  of  the  Services;  attempt  to  gain  unauthorized access to or impair any aspect of the Services or its related or network."
        Introhead8="3.2 OWNERSHIP" //subhead1
        con45="In  regard  of  The  Application,the Services,Company’s name, logos, brand names, products,  service  names,  emblems,  mascots,  designs,  trademarks,  services  marks, copyrights etcand all rights therein are and shall remain The Company’s property. Neither these Terms nor the user’s’ use of the Application or grant to the user any rights in  or  related  to  the  Applications  or  Services  except  for  the  limited  license granted above to use or reference in any manner."
        Introhead9="3.3 FALSE CLAIMS MADE BY USER TO OBTAIN LICENSE" //subhead1
        con46="Making a false claim to obtain in a license to use services or in any section in this document shall automatically disqualify the user from using the services. The user is advisedto cease and desist from using the service if making a false claim and if the user continues to do so, the user will be liable and subject to prosecution for fraud."
        Introhead10="4. TERMS OF USE OF THE SERVICES"
        Introhead11="4.1 ACCOUNTS" //subhead1
        con47="To  use  the  Services,  the  user  must  register  for  and  maintain  a  user  account (“Account”). The user must be an adult and reside within India to obtain an Account. The Account will be linked to an active mobile/cell phone number, either belonging to  the  user or  to  which  to  user  has  authorization  to  use.  Account  registration requires the user to submit personal information, including name, address, mobile /cell   phone   number,   age   and   organization   details,   including   name,   address, GSTN/PAN  card  numbers  etc,  as  well  as  at  least  one  valid  payment  method  that either belongs to the user or the user has authorization to use, (credit card / debit card /net banking/e-wallet or other accepted payment method). The user agrees to maintain accurate and up-to-date information in the Account, failure to do so may result  in  inability  to  access  and  use  the Services  or The Company’s termination of this Agreement with the user. Further, the user is solely responsible for all activity that  Account,  and  the  user  agrees  to  maintainthe  secrecy  of  the  user  Account username and password at all times. Unless otherwise permitted by The Company, the user may only possess one Account."
        con48="By  using  the  services,  the  user  hereby  agrees  and  undertakes  to  accept Fuelprocured  by The  Companyirrespective  of  the  Brand  of  theentity  whereFuelprocured from (the Fuelshall be procured only from anyAuthorizedOMC’s supplyLocation or from its fueling stations).The Companywill make thepaymentto the OMC  or  its  dealerfor  the  fuel  procured.  Procured  fuel  will  be  transported  to  the user’s location as per the route plan for the pooled orders from single/different users.  The  company  will  generate  the  bill/invoice  in  the name  of  the useron successful completion of delivery. The useragrees, and not challenge,to the charges levied  by  the  company.  The  user  agrees  topay  the  full  chargesto The  Companyduring  the time  of ordering  itself,  unless  other  arrangements  are  agreed  to separately between the specific user and the company.Charges will include Product Price,  transportation  &  transferring  of  Fuel  procured,  Convenience  fees,Payment Facilitation,applicable taxes, other applicable charges etc.,and the user agrees to not challenge these charges."
        con49="By using the services the userhereby agrees to hire/lease The Company’s equipment with operator to transport the Fuelprocured to the User’slocation and transfer Fuelprocured into  a  container,  PESO  approved,of  the user’schoosing.  By  using  the services, the user hereby undertakes that the user is not a Dealer/Agent/representative/Distributor  appointed  by  a the  Companyengaged  in marketing of Fuelin India and undertakes that the user is not an individual or the Companybarred from procuring Fuelby any Government Agency or OMC. Making a  false  claim  in  this  regard  or  any  section  in  this  document  shall  automatically disqualify the user from using the Services. The user is advised to cease and desist from using the Services if making a false claim and if the user continues to do so, the user will be liable and subject to prosecution for having committed fraud with The Company.  (All  relevant  clauses  for  the  applicable  law  will  be  used  for  proceeding with  prosecution  by The  Companyincluding  statutory  reporting  of  breach  of contract/agreement if and when a user’sdefault is recognized by The Companyor reported  to The  Company,  and The  Companydeclares  that  it  will  cooperate  any other agency Government or Public to aid such a prosecution, if charge are pressed by such an agency)."
        con50="By using the services, the user hereby undertakes:"
        con51="1. That the mobile/cell phone number used to register for the account, belongsto the user or the user has authorization to use the number for the specific purpose of registering with The Company."
        con52="2. That  the  payment  method  used  to  pay  to The  Companyfor  services provided, either belongs to the user or the user has authorization to use the payment method provides for the specific purpose of paying for the services."
        con53="3. To pay all and any applicable charges,during the time of ordering in the application,to The Companyincluding fuel price,transportation and transfer,payment  facilitation  charges,convenience  charges,  applicable taxes, other applicable chargesetc.,will be charged as applicable."
        con54="4. That the user is acting on the user’sown accord and is bound to all terms and conditions mentioned in wither document (Section 1thru 5) when using the services and further is fully responsible for all actions taken by the user."
        con55="5. To have access right and privileges to property (Movable or Immovable) at the  time  of  delivery  and  transferring  to Fuel.(See  Section 6:  Disclaimers, Limitation of Liability and Indemnity)"
        con56="6. To not authorize third parties to use their Account."
        con57="7. To not assign or transfer the Account to any other person or entity."
        con58="8. To  comply  with  all  applicable  laws and  guidelines  by  PESO,OMC  and  any other statutory body when using theServices."
        con59="9. To use the Services only for lawful purposes and own usage."
        con60="10. That  the  Services  will  not  be  used  to  cause  nuisance,  inconvenience,  or property damage (Movable and Immovable), whether to The Companyor to any other party."
        con61="11. To not causedirectly or indirectly any damage, nuisance, inconvenience to the Mobile Dispenser, personnel & assets of operatorand the company in any way."
        con62="12. The  user  to  ensure  the  safety  of  Mobile  Dispenser,personnel  &  assets  of operator and the company while presentin the user’s location for delivery."
        con63="13. In case of any kind of damage to the Mobile Dispenser, personnel & assets of operator and the company the user to take full responsibility and bear the damage costand compensation deemed fit to the company."
        con64="14. Services will  not  be  used  to  cause  nuisance,  inconvenience,  or  property damage (Movable and Immovable), whether to The Company or to any other party."
        con65="15. That in certain instances the user may be asked to provide proof of identity to access or use the Services and thatthe user may be denied access to use of  the  Services  if  the  user  refuses  to  provide  proof  of  identity  to  check compliance of mentioned undertakings."
        con66="16. That all information that has been shared by the user with The Companyis factually correct and accurate."
        con67="17. That in  casethere  are  quality  issues  with  the Fuelprocured,  the  user  will direct  all  claims  to  the  point  of  purchase  where Fuelwas  procured  by the Company"
        con68="18. That any and all benefits including but not limited to reimbursements/paybacks/cash back/points/miles whether or not obtained when  procuring Fueland/or  whether  or  not  derived  from  use  of  cashless transactions and/or whether or not derived from using fleet cardsor reward structure  or  loyalty  benefit  programswill  be  the  sole  property  of The Companyand the user hereby acknowledges and agrees that the user has no claim on any such benefits. Further, even in casea residual claim exists the user agrees to transfer any all such residual claims to The Companyfor monetization.  The  user  hereby  waives  all  direct  and  indirect  claims  and subsequently transfers all such benefits to The Company."
        Introhead12="4.2 TEXT/SMS MESSAGING" //
        con69="By  creating  an  Account  and  using  these  services  the  user  agrees  to  receive informational text (SMS) messages."
        Introhead13="4.3 USER PROVIDED CONTENT" //
        con70="The  Companymay,  in  its  sole  discretion,  allow  or  request  the  user  to  present, upload,  or  make  available  text,  audio-visual  content  and  information,  including commentary and feedback related to the Services (User Content).By providing User Content  to The  Company,  the  user  agrees  to  give  to The  Companya  worldwide, perpetual,  irrevocable,  royalty-free  license,  with  the  right  to  sublicense, use  copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content ( including in connection with the  Services  and The  Company’s business and on third –party  sites  and  services), without further notice to or consent from the user, and without the requirement of payment to the user or any other person or entity."
        Introhead14="4.4 NETWORK ACCESS AND DEVICES" //
        con71="The user is solely responsible for obtaining the data network access necessary to use the Services. The user’smobile/cell network’s data and fees may apply if the user accesses or uses the Services from a wireless-enabled device. The user is responsible for acquiring and updating compatible hardware or devices necessary to access and use the Services and Applications and any updates thereto. The Companydoes not guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In addition, the user should be aware that the Services may be subject to malfunctions and delays inherent in the use of the internet and electronic communications."
        Introhead15="4.5 FALSE CLAIM MADE BY USER TO USE SERVICES" //
        con72="Making  a  false  claim  to  obtain  a  License  to  use  services  or  in  any  section  in  this document shall automatically disqualify the user from using the Services. The user is encouraged to cease and desist from using the services if making a false claim and if the user continues to do so, the user will be liable and subject to prosecution for having committed fraud with The Company."
        Introhead16="5. PAYMENT TERMS"
        con73="The user understands, acknowledges and undertakes following:"
        con74="That use of the Services will result in charge to the user(“Charges”)."
        con75="The  Charges  will  include  Product  Price,  transportation  &  transferring  of  Fuel procured, Convenience fees, Payment Facilitation, applicable taxes, other applicable charges etc. Theuser agrees not todispute or challenge these chargesand pay in full during the time of ordering. The bill/invoice will be in the name of the user."
        con76="The user acknowledges that the fuel procurement from OMCs will derive benefits (including  but  not  limited  to  reimbursements/paybacks/cash  back/points/miles whether or not obtained when procuring Fueland/or whether or not derived from use of cashless transaction and/or whether or not derived from using fleet cards or reward  structure  or  loyalty  benefit  programs  by  OMCs)  which  will  be  the  sole property  of The  Companyand  the  user  hereby  acknowledge  and  agrees  that  the user has no claim on any such benefits. Further, even in case a residual claim exists the user agrees to transfer any and all such residual claims to The Companyfrom monetization. The user hereby waives all direct and indirect claims and rights and subsequently transfer to The Company. These charges may notbe reflected in the user’sbill."
        con77="The user agrees to be liable to pay all applicable charges described above, even if the user chooses not to accept the Fuel, refund process will be initiated as per the company’s refund and cancellation policy. In case of non-acceptanceof Fuelby the user,  the  user  agrees  and  undertakes  that The  Companyis  free  to  appropriately dispose the Fuelor use the Fuelas The Companymay deem fit and further the user hereby relinquishes all claims on the order."
        con78="All  Charges  are  due  immediately  and  payment  will  be  facilitated  by The  Companyusing the preferred payment method designated in the user’sAccount, after which The  Companywill  send  the  user  a  receipt  by  email.  If user’sprimary  Account payment method is determined to be expired, invalid or otherwise not able to be charged, the user agrees that The Companymay use a secondary payment method specified in the Account,if available."
        con79="As between the user and The Company,The Companyreserves the right to establish, remove and revise Charges for any or all services or goodsor products(as applicable) obtain through use of the Services at any time in The Company’s sole discretion. The Companymay from time to time provide certain user’swith promotional offers and discounts that may result in different amount charge for the same or similar services or  goods  obtainthrough  the  use  of  the  Services,  and  the  user  agrees  that  such promotional offers and discounts, unless also made available to the user, shall have no bearing on use of the Services or the Charges applied to the user in question. The user  may  elect  to  cancel  request  for  Services as  per  the  Refund  and  Cancellation Policy. The  user  understands  that,  while  the  user  is  free  to  provide  additional payment as a voluntary gratuity to theoperator or its personnel, however the user is under no obligation to do so."
        con80="The user expressly authorize The Companyto charge the applicable fees and taxes on  said  payment  method  as  well  as  taxes  and  other  charges  incurred  thereto  at regular  intervals,  all  of  which  depend  on  the  particular  membership  and utilizedservices."
        con81="We  understand  that  the  user  might  cancel  an  account,  but  please  know  that The Companywill not provide any refund(s) and the user will be responsible for paying any balance due on the account. The user agrees that The Companymay charge any unpaid  fees  thru  provided  payment  method  and/or  send  the  user  a  bill  for  such unpaid fees."
        con82="All statutory and payment facilitation charges including taxes, levy’s, charges, surcharges, cesses, fees will be charged as applicable."
        con83="The user hereby undertakes that the payment method used to pay to The Companyfor services provided, either belongs to the user or the user has authorization to use the payment method provided for the specific purpose of paying for the services."
        Introhead17="6. DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY"
        Introhead18="6.1 DISCLAIMERS" //
        con84="The  services  are  provided  as  is  and  as  available  basis, The  Companydisclaims  all representations and warranties, express, implied, or statutory, not expressly set out in  these  terms,  including  the  implied  warranties  of  merchantability,  fitness  for  a particular  purpose  and  non-infringement.  In  addition, The  Companymakes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the services or any services or Fuelor productsrequested through the use of the services, or that the services will be uninterruptedor error-free. The  Companydoes  not  guarantee  the  quality,  suitability,  safety  of Fuelor products provided to the user. The user agrees that the entire risk arising out of use of  the  services,  and  any  service  or Fuelor  productsrequested  in  connection therewith,  remains  solely  with  the  user,  to  the  maximum  extent  permitted  under applicable law, The Companydoes not sell, putup for sale, put on the market, vend, trade,  retail,  wholesale,  market,  promote,  advertise, publicizea Fuel,  transact  an unauthorized sale or unauthorized exchange or unauthorized purchase, but offers the  user  a  technology  platform  where  a  user  can access  and purchase Fueland subsequently have it transported to place of the user’schoosing and transferred into a container of the user’schoosing."
        Introhead19="6.2 LIMITATION OF LIABILITY" //
        con85="The Companyshall not be liable for indirect, incidental, special, exemplary, punitive, or  consequential  damages,  including  lost  profits,  lost  data,  personal  injury,  or damage  to  property  (both  moveable  and  immovable)  related  to,  or  in  connection with, or otherwise resulting from any use of the services or Fuelor productseven if The  Companyhas  been  advised  of  the  possibility  of  such  damages. The  Companyshall not be liable for any damages, liability or losses arising out of the user’suse of or reliance on the services or use of Fuelor products procured using the services or the user’sinability to access or use the services. The Companyshall not be liable for delay  or  failure  in  performance  resulting  from  causes  beyond The  Company’s control.  In  case  of  quality  issues  with  the Fuelor  products procured,  the  user  is directed to address all claims to the point of purchase where the Fuelwas procured by The Compan. The user hereby indemnifies The Companyfrom any and allclaim that  may  arise  out  of  quality  issues  with  the Fuelor  products procured  by The Companyto serve the user. In no event shall The Company’s total liability to the user in connection with the services for all damages, losses and causes of action exceedten thousand INR (INR 10,000). In addition , The Companyshall not be liable to the user for any damages arising out of, or relating to any of the following regardless of whether sounding in contract, tort (Including negligence) or otherwise:"
        con86="1. Any siphoning of Fuelfrom the user’scontainer."
        con87="2. Access  by  any  third  party  to  any  area  within  which  the user’smovable  or immovable property is located, whether or not the user has provided us any key, pin or passcode to allow us access to such area."
        con88="3. Any spillageor pilferage or theftof Fuelor products, whether or not related to the services; and The Fuelused to fill the user’scontainer."
        con89="4. Any  damage  to  the user’simmovable  or  movable  or  other  real  or  tangible personal property, whether owned by the user or by a third party, by agreeing to these terms of use and using the services. The user assumes all liability for any  damages  arising  from The  Company’s access movable or immovable property. The user agrees that any insurance,under which the user may be insured,  which  may  be  available  to  address  any  damage  arising  out  of,  or related to any of the preceding listed events,shall be the source of insurance to  address  any  such  damages.  The  user  understand  and  agrees  that  any insurance  under  which The  Companyis  insured  shall  not  be  the  primary source of insurance to address any damages and may only be accessed at The Company’s sole discretion and election or not at all."
        Introhead20="6.3 RESTRICTIONS ON USE" //
        con90="The usersof the Website and/or the Services shall not host, display, upload, modify, publish, transmit, update or share any information that:"
        con91="I. belongs  to  another  person  and  to  which  the  User  does  not  have  any proprietary right;"
        con92="II. grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, pedophilic,   libelous,   invasive   of   another's   privacy,   hateful,   or   racially, ethnically   objectionable,   disparaging,   relating   or   encouraging   money laundering or gambling, or otherwise unlawful in any manner whatever;"
        con93="III. harmminors in any way;"
        con94="IV. infringes any patent, trademark, copyright or other proprietary rights;"
        con95="V. violates any law for the time being in force;"
        con96="VI. deceives  or  misleads  the  addressee  about  the  origin  of  such  messages  or communicates  any  information  which  is  grossly offensive  or  menacing  in nature;"
        con97="VII. impersonates another person;"
        con98="VIII. contains  software  viruses  or  any  other  computer  code,  files  or  programs designed  to  interrupt,  destroy  or  limit  the  functionality  of  any  computer resource;"
        con99="IX. Threatens  the  unity,  integrity,  defense,  security  or  sovereignty  of  India, friendly relations with foreign states, or public order or causes incitement to the  commission  of  any  cognizable  offence  or  prevents  investigation  of  any offence or is insulting any other nation."
        con100="The  Companyretains  the  right  to  remove  from  the  Website  any  content  that contravenes any of the above conditions."
        Introhead21="6.4 DISCLAIMER OF WARRANTIES" //
        con101="Please note that your use of the Website and the Services shall be at your sole risk. The  Companydisclaims  all  warranties  of  any  kind,  whether  express  or  implied, including, but not limited to non-infringement of third party rights with respect to the contents of the Website, or any reliance upon or use of the Website contents or Fuels."
        con102="Further, The Companymakes no warranty:"
        con103="I. That the content made available on the Website is complete,updated or accurate; or"
        con104="II. That the third party information made available on the Website or the third party links made available on the Website are accurate, reliable or complete."
        Introhead22="6.5 INDEMNITY" //
        con105="The  user  is  responsible  for  use  of  the  Services,  and  hereby  indemnifies  and  holds The   Companyand   its   officers,   directors,   employees, operators,   partners, consultants,  affiliates,  subsidiaries,  and agent/representative(together the “The Company” Entities) from and against any and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees ), arising out of or in any way connected with;"
        con106="1. The user’saccess to use of or alleged use of the Services or goods (procured by The  Companyto  serve  the  user,read  preceding  for  details)  procured through use of the Services;"
        con107="2. The user making any false claim to either obtain a license to Access Services or Application or to Use Services;"
        con108="3. Sharing of inaccurate, false, misleading or incorrect information uploaded by the user"
        con109="4. The user’sbreach  or  violation  of  any  of  these  Terms,  any  representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation;"
        con110="5. The Company’s use of user content;"
        con111="6. The user’sviolation of the rights of any third party, including any intellectual property right or publicity, confidential, other property, or privacy, right; or,"
        con112="7. Any dispute or issue between the user and any third party;"
        con113="8. Access rights and privileges to property (Movable or Immovable) at the time of delivery and transferring to Fuel."
        con114="The Companyreserves the right, at its own expense to assume the exclusive defenseand control of any matter otherwise subject to indemnification by the user (without limitingthe user’sindemnification  obligation  with  respect  tothat  matter),  and  in that case, the user agrees to co-operate with The Company’s defenseof that claim."
        Introhead23="7. MISCELLANEOUS PROVISIONS"
        Introhead24="Disputes:"
        con115="Theuser  agrees  that  any  dispute,  claim  or  controversy  arising  out  of  or relating to these Terms or the breach, terminated, enforcement, interpretation or 
     validity thereof or the use of Services (collectively, “Disputes”) will:First-be brought to the attention of The Company’s management through the Grievances/Feedback option in Application or in writing directly to the company. The Management, after scrutinizing the claim will reply to the user with a decision on the matter within aperiod  of  60  days.  Such  a  decision,  if  challenged  by  the  user  in  respect  of  every matter so referred shall, subject to adjudication by courts in the State of Telangana."
        Introhead25="Grievances:"
        con116="Ifa User has any feedback or questions or complaints or grievances or feedback regarding  the  Websiteor  application  or  services  or  fuel  or  productsthe User  may  reach  out  to The  Companycustomer  support  atsupport@dezal.in(the“Customer Relationship Management (CRM)Officer”).  The  CRMOfficer  shall address anyfeedback or questions or complaint or grievance that is raised by a User within a period of one (1) month from when it is raised."
        Introhead26="Force  Majeure:"
        con117="The  Companyshall  not  responsible  for  any cancellation,  delay, cessation,   interruption   in   the service   and   its performance   of   its   obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism,  armed  conflict,  labor  strike,  lockout,  boycott orany  uncontrollable& unforeseencircumstances. The Companymay at any time, in its sole discretion andwithout advance notice to you, cease operation of the Website and the provision of the Servicesand products."
        Introhead27="Interpretation & Severability:"
        con118="If any provision of these Site Terms & Conditions shall be deemed unlawful, void, or for any reason is unenforceable, then that provision shall be deemed severable from the rest of terms & Conditions and shall not affect the  validity  and  enforceability  of  any  remaining  provisions.  For  legal/statutory interpretation and reference, the English version of our digital platform/s including terms of use & privacy policy, as amended from time to time, shall prevail and be binding upon the parties."
        Introhead28="No  Waiver:"
        con119="A  delay  or  failure  by The  Companyto  exercise  or  enforce  any  right  or provision of this Agreement will not constitute a waiver of such right or provision."
        Introhead29="Notices:"
        con120="All notices given to a User by The Companyor by a User to The Companyor another User shall be in writing and in the Englishlanguage. Such notice shall be sent by  e-mail  or  mailed  by  a  prepaid  internationally-recognized  courier  service  to  the intended  recipient  at  the  address  set  out  below,  or  any  changed  address  that  is notified by either Party:"
        Introhead30="Notice to The Company:"
        con121="M/s GRIT DEZAL ECOMM PRIVATE LIMITED"
        con122="Ho.No. 31-3-1813, Plot No. 24/A, PARIMALA Colony, Veera NGOs Colony, Warangal, Telangana, India, 506370"
        con123="Email:support@dezal.in"
        Introhead31="Notice to User:"
        con124="At the email address provided by you at the time of registration on the Website."
        Introhead32="Governing Law:"
        con125="This  Agreement  is  governed  by  the  provisions  of  Indian  law, including but not limited to the following:"
        con126="1. the Indian Contract Act, 1872;"
        con127="2. the (Indian) Information Technology Act, 2000;"
        con128="3. the (Indian)   Information   Technology   (Reasonable   Security   Practices   and Procedures and Sensitive Personal Information) Rules, 2011; and"
        con129="4. the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011."
        con130="This Website originates from the State of Telanganain India. This Agreement will be governed  by  the laws  that  are  applicable  in  the  StateofTelangana.  By  using  this Website,  you  consent  to  the  jurisdiction  and  venue  of  the  courts  located  in Telangana,  India  in  connection  with  any  action,  suit,  proceeding  or  claim  arising under or by reason of this Agreement."
        Introhead33="Termination:"
        con131="The Companymay terminate your access to the Website without any notice to you if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this Agreement."
      />
    </>
  );
}

export default terms;
