import React from "react";
import styled from "styled-components";
import Background from "../../assets/images/legal/dezal_back.svg"
import privacyback from "../../assets/images/legal/privacy.svg";
import {Helmet} from "react-helmet";


function privacy({Heading,Title1,Introhead,Con1,Introhead1,con2,Introhead2,con3,Introhead3,con4,con5,Introhead4,Introhead5,con6,con7,span
,con8,con9,con10,con11,con12,con13,con14,con15,con16,Introhead6,con17,Introhead7,Introhead8,Introhead9,Introhead10,Introhead11,con18,con19,con20,con21,con22
,Introhead12,Introhead13,Introhead14,Introhead15,con23,con24,con25,con26,con27,con28,con29,con30,con31,con32,con33,con34}) {
    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy | Dezal.in </title>
                <link rel="canonical" href="http://dezal.in/privacy_policy" />
            </Helmet>
        <Termback >
            <h1>{Heading}</h1>
            <img src={privacyback}></img>
        </Termback> 
        <Fulldetail>
            <Para1><Myspan>{span}</Myspan>{Title1}</Para1>
            <Subhead>{Introhead}</Subhead>
            <Myorder>
                <Mylist>Name</Mylist>
                <Mylist>Email ID</Mylist>
                <Mylist>Phone Number</Mylist>
                <Mylist>Address</Mylist>
                <Mylist>Location Details</Mylist>
                <Mylist>IP Address</Mylist>
                <Mylist>Browser Details</Mylist>
                <Mylist>Any Other Details</Mylist> 
            </Myorder> 
            <Para1>{Con1}</Para1>
            <Subhead>{Introhead1}</Subhead>
            <Para1>{con2}</Para1>
            <Subhead>{Introhead2}</Subhead>
            <Para1>{con3}</Para1>
            <Subhead>{Introhead3}</Subhead>
            <Para1>{con4}</Para1>
            <Para1>{con5}</Para1>
            <Subhead1>{Introhead4}</Subhead1>
            <Para1>{con6}</Para1>
            <Subhead1>{Introhead5}</Subhead1>
            <Para1>{con7}</Para1>
            <Para1>{con8}</Para1>
            <Para1>{con9}</Para1>
            <Para1>{con10}</Para1>
            <Para1>{con11}</Para1>
            <Para1>{con12}</Para1>
            <Para1>{con13}</Para1>
            <Para1>{con14}</Para1>
            <Para1>{con15}</Para1>
            <Para1>{con16}</Para1>
            <Para1>{con17}</Para1>
            <Subhead>{Introhead6}</Subhead>
            <Para1>{con18}</Para1>
            <Para1>{con19}</Para1>
            <Subhead>{Introhead7}</Subhead>
            <Para1>{con20}</Para1>
            <Subhead>{Introhead8}</Subhead>
            <Para1>{con21}</Para1>
            <Subhead>{Introhead9}</Subhead>
            <Para1>{con22}</Para1>
            <Subhead>{Introhead10}</Subhead>
            <Subhead1>{Introhead11}</Subhead1>
            <Para1>{con23}</Para1>
            <Subhead1>{Introhead12}</Subhead1>
            <Para1>{con24}</Para1>
            <Subhead1>{Introhead13}</Subhead1>
            <Para1>{con25}</Para1>
            <Subhead>{Introhead14}</Subhead>
            <Para1>{con26}</Para1>
            <Para1>{con27}</Para1>
            <Para1>{con28}</Para1>
            <Subhead>{Introhead15}</Subhead>
            <Para1>{con29}</Para1>
            <Para1>{con30}</Para1>
            <Para1>{con31}</Para1>
            <Para1>{con32}</Para1>
            <Para1>{con33}</Para1>
            <Para1>{con34}</Para1>
            
        </Fulldetail>
        </>
)}


const Myspan = styled.span`
color : #4E569F;
font-weight: 500;
`


const Termback = styled.div`
   background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1% 3%;
    color: white;
    display: flex;

    h1{
        font-size: 50px;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding: 0% 8%;
    width: 60%;
    margin: auto 0%;
    }


    @media screen and (max-width:1040px){
        h1{
            font-size: 40px;
        }
        img{
            width: 15%;
        }
    }

    @media screen and (max-width:800px){
        h1{
            font-size: 30px;
        }
    }

    @media screen and (max-width:650px){
        display: block;

        h1{
            font-size: 25px;
    width: 100%;
    padding: 2% 0%;
    text-align: center;
        }
        img{
            margin: 4% auto;
    display: block;
    width: 25%;
        }
    }

`;

const Fulldetail = styled.div`
background-color: #ffffff;
    width: 80%;
    margin: 3% auto;
    text-align: center;
    letter-spacing: 0.3px;
    
`

const Para = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;


    @media screen and (max-width:650px){
        font-size: 12px;
    text-align: justify;
    }
`

const Para1 = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    text-align: left;

    @media screen and (max-width:650px){
        font-size: 12px;
    }
`

const Subhead = styled.h2`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`

const Subhead1 = styled.h6`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 0%;


`

const Myorder = styled.ol`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
`
const Mylist = styled.li`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`


export default privacy;
