import React from "react";
import styled from "styled-components";
import { Title } from "../styledComponent/styled";
import CovidImage from "../assets/images/background/covidIcon.svg";
import CovidMobileImage from "../assets/images/background/covidmobileicon.svg";
import DownloadApp from "../components/downloadApp/DownloadApp";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const SubContainer = styled.div`
  width: 86.92%;
`;
const NewTitle = styled(Title)`
  width: 93%;
  margin-bottom: 26px;
`;
const ListHeading = styled.p`
  font: normal normal normal 16px Poppins;
  letter-spacing: 0px;
  color: #525252;
  opacity: 1;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (max-width: 580px) {
    font-size: 14px;
  }
`;
const OL = styled.ol`
  margin-left: -20px;
`;
const Picture = styled.img`
  width: 100%;
`;
const covidGuidelines = () => {
  return (
    <Container>
      <SubContainer>
        <NewTitle>
          Covid-19 Spreads Primarily From Person To Person. Fighting This
          Disease Is Our Joint Responsibility.
        </NewTitle>
        <ListHeading>
          Protect yourself and others by following these 6 simple precautions as
          daily habits:
        </ListHeading>
        <OL>
          <li>
            <ListHeading>Clean your hands often</ListHeading>
          </li>
          <li>
            <ListHeading>
              Cough or sneeze in your bent elbow - not your hands!
            </ListHeading>
          </li>
          <li>
            <ListHeading>Avoid touching your eyes, nose and mouth</ListHeading>
          </li>
          <li>
            <ListHeading>
              Limit social gatherings and time spent in crowded places
            </ListHeading>
          </li>
          <li>
            <ListHeading>
              Avoid close contact with someone who is sick
            </ListHeading>
          </li>
          <li>
            <ListHeading>
              Clean and disinfect frequently touched objects and surfaces
            </ListHeading>
          </li>
        </OL>
        <Picture src={CovidImage} />
      </SubContainer>
      <DownloadApp marginTop="58px" marginBottom="61px" />
    </Container>
  );
};

export default covidGuidelines;
