import React from "react";
import styled from "styled-components";
import Navbarlogo from "../../assets/images/logo/logotm.png";
import { NavLink, Link } from "react-router-dom";
import CloseIcon from "../../assets/images/logo/closeIcon.svg";

const Navbardiv = styled.div`
  background-color: white;
  padding: 2% 6%;
  box-shadow: 0px 5px 5px #7b472e2e;
  z-index: 9;
  position: sticky;
  height: 60px;
  top: 0px;
  display: flex;
  align-items: center;
  /* display: flex; */

  img {
    width: 35px;
    padding-left: 5%;
  }

  @media screen and (max-width: 650px) {
    padding: 4% 2%;
    padding-left: 10%;

    img {
      width: 20px;
    }
  }
`;
const StyledLink = styled(NavLink)`
  text-decoration: none !important;
  margin-right: 3%;
  font: normal normal medium 16px Poppins;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-left: ${(props) => props.marginLeft};


  @media screen and (max-width: 580px){
    display: none;
  }
`;

const SignUpButton = styled.button`
  width: 109px;
  height: 42px;
  background: #e65814 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  opacity: 1;
  font: normal normal 600 16px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 21px;
`;
const SignInButton = styled.p`
  font: normal normal 600 16px/30px Poppins;
  letter-spacing: 0px;
  color: #e75815;
  opacity: 1;
  margin-left: 19.32%;
  cursor: pointer;
`;
const CollapseButton = styled.div`
  width: 27px;
  height: auto;
  display: none;
  margin-left: 85%;
  @media screen and (max-width: 580px) {
    display: block;
  }
`;
const ButtonLine = styled.div`
  width: 27px;
  height: 2px;
  margin-bottom: 5px;
  background: #e65814 0% 0% no-repeat padding-box;
  opacity: 1;
`;
const Linkwrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Close = styled.img`
  width: 16px;
  height: 18px;
`;

function Navbar1({ openMobileNav, mobileNav }) {
  return (
    <>
      <Navbardiv>
       <Linkwrapper>
          <StyledLink
            marginLeft="7%"
            to="/"
            activeStyle={{ color: "#E65814" }}
            exact="true"
          >
            Home
          </StyledLink>
          <StyledLink to="/about_us" activeStyle={{ color: "#E65814" }}>
            About Us
          </StyledLink>
          <StyledLink to="/career" activeStyle={{ color: "#E65814" }}>
            Career
          </StyledLink>
          <StyledLink to="/faq" activeStyle={{ color: "#E65814" }}>
            FAQS
          </StyledLink>
          <StyledLink to="/contact_us" activeStyle={{ color: "#E65814" }}>
            Contact us
          </StyledLink>
          <StyledLink to="/ourservices" activeStyle={{ color: "#E65814" }}>
            Our services
          </StyledLink>
        </Linkwrapper>
        {/* <SignInButton >Sign In</SignInButton>
        <SignUpButton>Sign Up</SignUpButton> */}
        {mobileNav && <Close src={CloseIcon} onClick={() => openMobileNav()} />}
        {!mobileNav && (
          <CollapseButton onClick={() => openMobileNav()}>
            <ButtonLine />
            <ButtonLine />
            <ButtonLine />
          </CollapseButton>
        )}
      </Navbardiv>
    </>
  );
}

export default Navbar1;
