import React from "react";
import styled from "styled-components";

const Conatiner = styled.div`
  width: 47%;
  height: 181px;
  background: #f9fbfb 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 34px;
  background-color: ${(props) => props.bgColor};

  @media screen and (max-width: 945px) {
    width: 80%;
    margin: 2% auto 2% auto;
    display: block;
    height: 134px;
    padding: 5%;
    text-align: center;
  }
`;
const Title = styled.p`
  font: normal normal 600 20px Poppins;
  letter-spacing: 0px;
  color: #0d0e50;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 12px;

  @media screen and (max-width: 650px) {
    margin: 0% 0% 2% 0%;
    font-size: 17px;
  }
`;
const Content = styled.div`
  width: 94%;
  height: 81px;
  text-align: left;
  font: normal normal normal 13px/25px Poppins;
  letter-spacing: 0.07px;
  color: #525252;
  opacity: 1;

  @media screen and (max-width: 650px) {
    height: 0%;
    width: 100%;
    font: normal normal normal 12px/21px Poppins;
  }
`;

const MissionVisionCard = ({ title, content, bgColor }) => {
  return (
    <Conatiner bgColor={bgColor}>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Conatiner>
  );
};

export default MissionVisionCard;
