import React from "react";
import styled from "styled-components";
import Background from "../../assets/images/legal/dezal_back.svg"
import termsback from "../../assets/images/legal/term.svg";
import {Helmet} from "react-helmet";

function terms({Heading,Title1,Introhead,Con1,Introhead1,con2,Introhead2,con3,Introhead3,con4,con5,Introhead4,Introhead5,con6,con7,span,
con8,con9,con10,con11,con12,con13,con14,con15,con16,Introhead6,con17,Introhead7,Introhead8,Introhead9,Introhead10,Introhead11,con18,con19,con20,con21,con22
,Introhead12,Introhead13,Introhead14,Introhead15,con23,con24,con25,con26,con27,con28,con29,con30,con31,con32,con33,con34,con35,con36,con37,con38,con39,con40,con41,con42,con43,con44,con45,con46,con47,con48,con49
,con50,con51,con52,con53,con54,con55,con56,con57,con58,con59,con60,con61,con62,con63,con64,con65,con66,con67,con68,con69,con70,con71,con72,con73,con74,con75,con76,con77,con78,con79,con80,con81,con82,con83
,con84,con85,con86,con87,con88,con89,con90,con91,con92,con93,con94,con95,con96,con97,con98,con99,con100,con101,con102,con103,con104,con105,con106,con107,con108,con109,con110,con111,con112,con113,con114,con115,con116,con117,con118,con119
,con120,con121,con122,con123,con124,con125,con126,con127,con128,con129,con130,con131,Introhead16,Introhead17,Introhead18,Introhead19,Introhead20,Introhead21,Introhead22,Introhead23,Introhead24,Introhead25
,Introhead26,Introhead27,Introhead28,Introhead29,Introhead30,Introhead31,Introhead32,Introhead33,Introhead34,Introhead35,Introhead36,Introhead37,Introhead38,Introhead39,Introhead40,Introhead41,Introhead42,Introhead43,Introhead44,Introhead45,Introhead46}) {
    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Terms of Services | Dezal.in </title>
                <link rel="canonical" href="http://dezal.in/terms_conditions" />
            </Helmet>
        <Termback >
            <h1>{Heading}</h1>
            <img src={termsback}></img>
        </Termback> 
        <Fulldetail>
            <Para1><Myspan>{span}</Myspan>{Title1}</Para1>
            <Para1>{Con1}</Para1>
            <Subhead>{Introhead}</Subhead>
            <Subhead1>{Introhead1}</Subhead1>
            <Para1>{con2}</Para1>
            <Subhead1>{Introhead2}</Subhead1>
            <Para1>{con3}</Para1>
            <Subhead>{Introhead3}</Subhead>
            <Subhead1>{Introhead34}</Subhead1>
            <Para1>{con4}</Para1>
            <Subhead1>{Introhead35}</Subhead1>
            <Para1>{con5}</Para1>
            <Subhead1>{Introhead36}</Subhead1>
            <Para1>{con6}</Para1>
            <Subhead1>{Introhead37}</Subhead1>
            <Para1>{con7}</Para1>
            <Subhead1>{Introhead38}</Subhead1>
            <Para1>{con8}</Para1>
            <Subhead1>{Introhead39}</Subhead1>
            <Para1>{con9}</Para1>
            <Subhead1>{Introhead40}</Subhead1>
            <Para1>{con10}</Para1>
            <Subhead1>{Introhead41}</Subhead1>
            <Para1>{con11}</Para1>
            <Para1>{con12}</Para1>
            <Subhead1>{Introhead42}</Subhead1>
            <Para1>{con13}</Para1>
            <Subhead1>{Introhead43}</Subhead1>
            <Para1>{con14}</Para1>
            <Subhead1>{Introhead44}</Subhead1>
            <Para1>{con15}</Para1>
            <Subhead1>{Introhead45}</Subhead1>
            <Para1>{con16}</Para1>
            <Subhead1>{Introhead46}</Subhead1>
            <Para1>{con17}</Para1>
            <Para1>{con18}</Para1>
            <Subhead>{Introhead4}</Subhead>
            <Para1>{con19}</Para1>
            <Para1>{con20}</Para1>
            <Para1>{con21}</Para1>
            <Para1>{con22}</Para1>
            <Para1>{con23}</Para1>
            <Para1>{con24}</Para1>
            <Para1>{con25}</Para1>
            <Subhead>{Introhead5}</Subhead>
            <Para1>{con26}</Para1>
            <Para1>{con27}</Para1>
            <Para1>{con28}</Para1>
            <Para1>{con29}</Para1>
            <Para1>{con30}</Para1>
            <Para1>{con31}</Para1>
            <Para1>{con32}</Para1>
            <Subhead>{Introhead6}</Subhead>
            <Para1>{con33}</Para1>
            <Para1>{con34}</Para1>
            <Para1>{con35}</Para1>
            <Para1>{con36}</Para1>
            
            <Subhead>{Introhead7}</Subhead>
            <Para1>{con37}</Para1>
            <Para1>{con38}</Para1>
            <Para1>{con39}</Para1>
            <Para1>{con40}</Para1>
            <Para1>{con41}</Para1>
            <Para1>{con42}</Para1>
            <Para1>{con43}</Para1>
            <Para1>{con44}</Para1>

            <Subhead1>{Introhead8}</Subhead1>
            <Para1>{con45}</Para1>

            <Subhead1>{Introhead9}</Subhead1>
            <Para1>{con46}</Para1>


            <Subhead>{Introhead10}</Subhead>
            <Subhead1>{Introhead11}</Subhead1>
            <Para1>{con47}</Para1>
            <Para1>{con48}</Para1>
            <Para1>{con49}</Para1>
            <Para1>{con50}</Para1>
            <Para1>{con51}</Para1>
            <Para1>{con52}</Para1>
            <Para1>{con53}</Para1>
            <Para1>{con54}</Para1>
            <Para1>{con55}</Para1>
            <Para1>{con56}</Para1>
            <Para1>{con57}</Para1>
            <Para1>{con58}</Para1>
            <Para1>{con59}</Para1>
            <Para1>{con60}</Para1>
            <Para1>{con61}</Para1>
            <Para1>{con62}</Para1>
            <Para1>{con63}</Para1>
            <Para1>{con64}</Para1>
            <Para1>{con65}</Para1>
            <Para1>{con66}</Para1>
            <Para1>{con67}</Para1>
            <Para1>{con68}</Para1>



            <Subhead1>{Introhead12}</Subhead1>
            <Para1>{con69}</Para1>

            <Subhead1>{Introhead13}</Subhead1>
            <Para1>{con70}</Para1>


            <Subhead1>{Introhead14}</Subhead1>
            <Para1>{con71}</Para1>

            <Subhead1>{Introhead15}</Subhead1>
            <Para1>{con72}</Para1>

            <Subhead>{Introhead16}</Subhead>
            <Para1>{con73}</Para1>
            <Para1>{con74}</Para1>
            <Para1>{con75}</Para1>
            <Para1>{con76}</Para1>
            <Para1>{con77}</Para1>
            <Para1>{con78}</Para1>
            <Para1>{con79}</Para1>
            <Para1>{con80}</Para1>
            <Para1>{con81}</Para1>
            <Para1>{con82}</Para1>
            <Para1>{con83}</Para1>
           
            <Subhead>{Introhead17}</Subhead>
            <Subhead1>{Introhead18}</Subhead1>
            <Para1>{con84}</Para1>

            <Subhead1>{Introhead19}</Subhead1>
            <Para1>{con85}</Para1>
            <Para1>{con86}</Para1>
            <Para1>{con87}</Para1>
            <Para1>{con88}</Para1>
            <Para1>{con89}</Para1>

            <Subhead1>{Introhead20}</Subhead1>
            <Para1>{con90}</Para1>
            <Para1>{con91}</Para1>
            <Para1>{con92}</Para1>
            <Para1>{con93}</Para1>
            <Para1>{con94}</Para1>
            <Para1>{con95}</Para1>
            <Para1>{con96}</Para1>
            <Para1>{con97}</Para1>
            <Para1>{con98}</Para1>
            <Para1>{con99}</Para1>
            <Para1>{con100}</Para1>


            <Subhead1>{Introhead21}</Subhead1>
            <Para1>{con101}</Para1>
            <Para1>{con102}</Para1>
            <Para1>{con103}</Para1>
            <Para1>{con104}</Para1>


            <Subhead1>{Introhead22}</Subhead1>
            <Para1>{con105}</Para1>
            <Para1>{con106}</Para1>
            <Para1>{con107}</Para1>
            <Para1>{con108}</Para1>
            <Para1>{con109}</Para1>
            <Para1>{con110}</Para1>
            <Para1>{con111}</Para1>
            <Para1>{con112}</Para1>
            <Para1>{con113}</Para1>
            <Para1>{con114}</Para1>

            <Subhead>{Introhead23}</Subhead>
            <Subhead1>{Introhead24}</Subhead1>
            <Para1>{con115}</Para1>

            <Subhead1>{Introhead25}</Subhead1>
            <Para1>{con116}</Para1>

            <Subhead1>{Introhead26}</Subhead1>
            <Para1>{con117}</Para1>

            <Subhead1>{Introhead27}</Subhead1>
            <Para1>{con118}</Para1>

            <Subhead1>{Introhead28}</Subhead1>
            <Para1>{con119}</Para1>

            <Subhead1>{Introhead29}</Subhead1>
            <Para1>{con120}</Para1>

            <Subhead1>{Introhead30}</Subhead1>
            <Para1>{con121}</Para1>
            <Para1>{con122}</Para1>
            <Para1>{con123}</Para1>

            <Subhead1>{Introhead31}</Subhead1>
            <Para1>{con124}</Para1>

            <Subhead1>{Introhead32}</Subhead1>
            <Para1>{con125}</Para1>
            <Para1>{con126}</Para1>
            <Para1>{con127}</Para1>
            <Para1>{con128}</Para1>
            <Para1>{con129}</Para1>
            <Para1>{con130}</Para1>

            <Subhead1>{Introhead33}</Subhead1>
            <Para1>{con131}</Para1>


            
        </Fulldetail>
        </>
)}

const Myspan = styled.span`
color : #4E569F;
font-weight: 500;
`


const Termback = styled.div`
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1% 3%;
    color: white;
    display: flex;

    h1{
        font-size: 50px;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding: 0% 8%;
    width: 60%;
    margin: auto 0%;
    }

    @media screen and (max-width:1040px){
        h1{
            font-size: 40px;
        }
        img{
            width: 15%;
        }
    }

    @media screen and (max-width:800px){
        h1{
            font-size: 30px;
        }
    }

    @media screen and (max-width:650px){
        display: block;

        h1{
            font-size: 25px;
    width: 100%;
    padding: 2% 0%;
    text-align: center;
        }
        img{
            margin: 4% auto;
    display: block;
    width: 25%;
        }
    }

`;

const Fulldetail = styled.div`
background-color: #ffffff;
    width: 80%;
    margin: 3% auto;
    text-align: center;
    letter-spacing: 0.3px;
    
`

const Para = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;

    @media screen and (max-width:650px){
        font-size: 12px;
    text-align: justify;
    }
`

const Para1 = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    text-align: left;

    @media screen and (max-width:650px){
        font-size: 12px;
    }
`

const Subhead = styled.h2`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`

const Subhead1 = styled.h6`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 0%;
`

const Myorder = styled.ol`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
`
const Mylist = styled.li`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`


export default terms;
