import React from "react";
import styled from "styled-components";
import { Title } from "../styledComponent/styled";
import QuestionCard from "../components/Faq/QuestionCard";
import DownloadApp from "../components/downloadApp/DownloadApp";
import { Helmet } from "react-helmet";

const Conatiner = styled.div`
  /* padding-left: 93px; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SubContainer = styled.div`
  width: 86.92%;
`;

const NewTitle = styled(Title)`
  margin-top: 72px;
  @media screen and (max-width: 580px) {
    margin-top: 30px;
  }
`;

const SubTitle = styled.p`
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 22px Poppins;
  letter-spacing: 0px;
  color: #2f1c4a;
  text-transform: capitalize;
  opacity: 1;
  margin-top: ${(props) => props.marginTop};
  @media screen and (max-width: 580px) {
    margin-top: 8px;
  }
`;

const faq = () => {
  const services = [
    {
      q: "Can Petrol delivered under the Diesel Door Delivery?",
      a: "No, as per the current government policy, Petrol cannot be marketed under the Diesel Door Delivery.",
    },
    {
      q: "Can a mobile fuel dispenser fill up barrels?",
      a: "Yes, you can fill up in small size approved metal containers.",
    },
    {
      q: "Can a mobile fuel dispenser fill fuel tanks of buses/ trucks?",
      a: "The purpose of Mobile fuel dispenser is to operate who are unable to purchase the fuel from their place.",
    },
    {
      q: "Can I place orders using Pay/Cash on Delivery?",
      a: "Yes, with minimum booking amount of Rs.1000 to confirm order. Balance to be paid on or before bowser arrives.",
    },
    {
      q: "Can I place/schedule orders in advance for future requirement of fuel?",
      a: "Yes, Future order functionality enables a user to place an order that will occur in the future. Please visit dezal app, to place/view/edit for future dated/timed orders or contact us on support@dezal.in and we will gladly help you on-board.",
    },
    {
      q: "How do I know my order has been confirmed?",
      a: "You can track your orders from the 'My Orders' section on your dezal account.",
    },
    {
      q: "What are dezal Operation hours?",
      a: "Currently, we are operating diesel on weekdays from Monday-Friday (8 am - 6 pm).",
    },
    {
      q: "Where does dezal get its fuel from? ",
      a: "dezal sources the fuel from Oil Marketing Companies (OMC) or its authorized dealers.",
    },
    {
      q: "How will you ensure the quality?",
      a: "We follow PESO & OMC guidelines and maintain all the prescribed standard operating procedures. We will ensure to provide you the document trail about the fuel quality from its source location and perform required quality test in your presence, before delivery.",
    },
    {
      q: "Is Doorstep delivery by dezal safe?",
      a: "Safety First is a culture at dezal. We are trained staff by professionals from petroleum industry to handle the fuel during the delivery process. ",
    },
    {
      q: "How dezal ensure delivery?",
      a: "Dezal has a priority to serve customer without fail.",
    },
    {
      q: "What is No-Contact delivery?",
      a: "We at dezal understand the COVID-19 protocols and we will ensure implement all safety parameters.",
    },
    {
      q: "How can I get in touch with the customer support team?",
      a: "write to us on support@dezal.in",
    },
  ];
  const paymentBilling = [
    {
      q: "What are the delivery charges?",
      a: "Delivery charge is the cost incurred to transport the fuel from supply location to the customer location. ",
    },
    {
      q: "What are the payment options available?",
      a: "We accept payment by NEFT, RTGS, Online - Net Banking Payment, Online - Card/UPI Payment and Cash on delivery at the time of delivery. If you prefer paying Online using Cards, we recommend you to check with your bank if they levy surcharges on your card. If there is any surcharge by the bank/intermediary, it will be borne by you (customer). For our suggested Payment Gateway partners in the app/website any excess charge incurred by the payment mechanism is displayed at the time of choosing the payment method. The same is payable by you (customer) over and above the product & delivery charges and is collected by the Payment Gateway provider, and not by dezal as a service charge.",
    },
    {
      q: "Is there a cancellation fee on dezal orders before dispatch?",
      a: "In case the Order is canceled before dispatch, no cancellation charges shall be leviable but in case the same is canceled thereafter then a cancellation fee shall be payable by the user to the Company. Customer reserves the rights to cancel the order before the product is dispatched.",
    },
    {
      q: "Is there a cancellation fee on dezal orders after dispatch?",
      a: "In case of post confirmation of the order, if the order gets delayed or cancelled due to any reason, then Company shall not be responsible to any party for cancellation of the order or any kind of delay charges or compensation for the supply in any eventuality. Cancellation of such orders will be processed on case to case basis; however the company reserves the right of final say.",
    },
    {
      q: "How can I view my current orders?",
      a: "After you sign into the dezal app, head to the section ‘My orders’ where you will be able to view all your orders and their current delivery status.",
    },
    {
      q: "How can I cancel my order and request for a refund?",
      a: "write to us on support@dezal.in",
    },
  ];
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAQ's | Dezal.in </title>
        <link rel="canonical" href="http://dezal.in/faq" />
      </Helmet>
      <Conatiner>
        <SubContainer>
          <NewTitle>FAQ’S-Frequently Asked Questions</NewTitle>
          <SubTitle>Services</SubTitle>
          {services.map((item, i) => (
            <QuestionCard q={item.q} a={item.a} key={i} />
          ))}
          <SubTitle marginTop="53px">Payments And Billing</SubTitle>
          {paymentBilling.map((item) => (
            <QuestionCard q={item.q} a={item.a} />
          ))}
        </SubContainer>
        <DownloadApp marginTop="60px" marginBottom="53px" />
      </Conatiner>
    </>
  );
};

export default faq;
