import React from "react";
import styled from "styled-components"


const ServiceContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 1% 5%;
max-width: 100%;


@media screen and (max-width:650px){
  padding: 1% 0%;
}
`

const Servicesimg = styled.div`
  width: 100%;
  margin-left: 3%;

  img{
      margin: 0% 1%;
      width: 31%;
  }

  @media screen and (max-width:650px){
    margin-left:0px;
    

    img{
      margin: 2% auto;
      display: block;
      width: 90%;
  }

  }
`


const Servicecards = ({image1 , image2 , image3}) => {

   

    
    return(
       <ServiceContainer>
         <Servicesimg >
          <img src = {image1}/>
          <img src = {image2}/>
          <img src = {image3}/>
         </Servicesimg>
        </ServiceContainer>
    );
};


export default Servicecards;