import React from "react";
import styled from "styled-components";

const Container = styled.div`
  /* width: 380px; */
  width: 29%;
  height: 445px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 22px #c79b9b1a;
  opacity: 1;
  padding-left: 21px;
  padding-right: 21px;
  @media screen and (max-width: 1300px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 1200px) {
    height: auto;
  }
  @media screen and (max-width: 560px) {
    width: 88%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Image = styled.img`
  width: 152px;
  height: 89px;
  margin-top: 19px;
`;
const Title = styled.p`
  font: normal normal 600 20px/20px Poppins;
  letter-spacing: 0.1px;
  color: #3d3d3d;
`;
const Para = styled.p`
  font: normal normal normal 13px/25px Poppins;
  letter-spacing: 0.07px;
  color: #525252;
  opacity: 1;
`;
const AboutDiselCard = ({ logo, title, para }) => {
  return (
    <Container>
      <Image src={logo} />
      <Title>{title}</Title>
      <Para>{para}</Para>
    </Container>
  );
};

export default AboutDiselCard;
