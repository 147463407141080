import React from "react";
import styled from "styled-components";
import { Title } from "../styledComponent/styled";
import Details from "../components/contactus/details";
import DownloadApp from "../components/downloadApp/DownloadApp";
import { Helmet } from "react-helmet";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Newtitle = styled(Title)`
  width: 86.92%;
  /* margin-left: 5%; */
  margin-top: 72px;
  @media screen and (max-width: 580px) {
    margin-top: 31px;
  }
`;

const Contactus = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Dezal.in </title>
        <link rel="canonical" href="http://dezal.in/contactus" />
      </Helmet>
      <Container>
        <Newtitle>Contact Us</Newtitle>
        <Details />
        <DownloadApp marginTop="5%" marginBottom="5%" />
      </Container>
    </>
  );
};

export default Contactus;
