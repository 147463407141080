import React from "react";
import styled from "styled-components";
import Background from "../../assets/images/legal/dezal_back.svg"
import privacyback from "../../assets/images/legal/shipping.png";
import {Helmet} from "react-helmet";


function Shipping({Heading,Title1,Con1,con2,con3,con4,con5,con6,con7,con9}) {
    return(
        <>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Shipping Policy | Dezal.in </title>
                <link rel="canonical" href="http://dezal.in/shipping_policy" />
            </Helmet>
        <Termback >
            <h1>{Heading}</h1>
            <img src={privacyback}></img>
        </Termback> 
        <Fulldetail>
            <Para1>{Title1}</Para1>
           
            <Para1>{Con1}</Para1>
           <Myorder>
                <Mylist>{con2}</Mylist>
                <Mylist>{con3}</Mylist>
                <Mylist>{con4}</Mylist>
                <Mylist>{con5}</Mylist>
                <Mylist>{con6}</Mylist>
                <Mylist>{con7}</Mylist>
               <Mylist>{con9}</Mylist> 
            </Myorder> 
           
            
        </Fulldetail>
        </>
)}






const Termback = styled.div`
   background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1% 3%;
    color: white;
    display: flex;

    h1{
        font-size: 50px;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding: 0% 8%;
    width: 60%;
    margin: auto 0%;
    }


    @media screen and (max-width:1040px){
        h1{
            font-size: 40px;
        }
        img{
            width: 15%;
        }
    }

    @media screen and (max-width:800px){
        h1{
            font-size: 30px;
        }
    }

    @media screen and (max-width:650px){
        display: block;

        h1{
            font-size: 25px;
    width: 100%;
    padding: 2% 0%;
    text-align: center;
        }
        img{
            margin: 4% auto;
    display: block;
    width: 25%;
        }
    }

`;

const Fulldetail = styled.div`
background-color: #ffffff;
    width: 80%;
    margin: 3% auto;
    text-align: center;
    letter-spacing: 0.3px;
    
`



const Para1 = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    text-align: left;

    @media screen and (max-width:650px){
        font-size: 12px;
    }
`





const Myorder = styled.ol`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    text-align: left;
`
const Mylist = styled.li`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    text-align: left;
    margin: 1% 0%;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`


export default Shipping;
