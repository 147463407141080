import React, { useState } from "react";
import styled from "styled-components";
import DownloadAppBg from "../../assets/images/background/downloadAppBg.svg";
import dezalWriitenLogo from "../../assets/images/logo/dezal-written-logo.svg";
import gpayBg from "../../assets/images/background/googleplay.svg";
import appStorBg from "../../assets/images/background/playstore.svg";
import Popup from "../popup/Popup";

const Container = styled.div`
  background-image: url(${DownloadAppBg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 87.04%;
  height: 211px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};

  @media screen and (max-width: 650px) {
    width: 100%;
    height: 150px;
    margin-bottom: 0%;
  }
`;
const Title = styled.div`
  /* height: 36px; */
  margin-top: 14px;
  text-align: left;
  font: normal normal 600 26px Poppins;
  letter-spacing: 0px;
  color: #0d0e50;
  opacity: 1;
  margin-bottom: 27px;

  @media screen and (max-width: 800px) {
    font: normal normal 600 23px Poppins;
  }

  @media screen and (max-width: 600px) {
    font: normal normal 600 15px Poppins;

    img {
      width: 50px;
    }
  }
`;
const RowWrapper = styled.div`
  display: flex;
`;
const Button = styled.div`
  background-image: url(${(props) => props.bgimage});
  background-size: contain;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: ${(props) => props.marginRight};
  cursor: pointer;
  background-repeat: no-repeat;

  @media screen and (max-width: 800px) {
    width: ${(props) => props.responsivewidth};
  }

  @media screen and (max-width: 600px) {
    width: ${(props) => props.mobilewidth};
  }
`;

const DownloadApp = ({ marginBottom, marginTop, popup }) => {
  const [popupState, setPopupState] = useState(false);

  function openPopup() {
    setPopupState(true);
  }

  function closePopup() {
    setPopupState(false);
  }

  return (
    <Container marginTop={marginTop} marginBottom={marginBottom}>
      <Title>
        Download app for latest <img src={dezalWriitenLogo} /> updates
      </Title>
      <RowWrapper>
        <Button
          width="173px"
          height="58.51px"
          bgimage={appStorBg}
          marginRight="14px"
          responsivewidth="150px"
          mobilewidth="130px"
          onClick={() => openPopup()}
        ></Button>
        <Button
          width="231px"
          height="58.51px"
          responsivewidth="205px"
          mobilewidth="175px;"
          bgimage={gpayBg}
          onClick={() => openPopup()}
        ></Button>
      </RowWrapper>
      <Popup trigger={popupState} closePopup={closePopup}></Popup>
    </Container>
  );
};

export default DownloadApp;
