import React from "react";
import styled from "styled-components";
import Footerlogo from "../../assets/images/logo/logotm.png"
import logo from "../../assets/images/logo/dezal-written-logo.svg";
import Instagram from "../../assets/images/logo/instagram.svg";
import Facebook from "../../assets/images/logo/facebook.svg";
import Twitter from "../../assets/images/logo/twitter.svg";
import { Link } from "react-router-dom";


/*

  Footer of the application

*/

const FooterDiv = styled.div`
  background: #F2F2F2;
  text-align: center;
  margin: auto;
  padding-top: 2%;
  padding-bottom: 1%;
  font-family: 'Poppins', sans-serif;

  

  a {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    text-decoration: none;
    color: #656565;
  }

  hr{
    width: 80%;
    border-bottom: 0.5px gray;
  }

  

  small {
    font-size: 16px;
    font-weight: 500;
    margin: 0% 0.5%;
    color: #656565;
    letter-spacing: 0.3px;
    line-height: 50px;
  }


  @media (max-width: 600px) {
    padding-top: 5%;
    padding-bottom: 5%;

    small {
      font-size: 13px;
    }

    a {
      font-size: 15px;
    }
  }
`;

const Footerlink = styled(Link)`
display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    text-align: left;
    width: 100%;
`

const Subfooterdiv = styled.div`
padding: 1% 0% 1% 8%;
    display: flex;
    

  a{
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    text-align: left;
    width: 100%;
  }
  .pages {

    width: 25%;


    img{
    width: 45px;
   
    
  }
    a{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-decoration: none;
    color: #656565;
    width: 100%;
    text-align: left;
    line-height: 35px;

  }
  p{
    text-align: left;
    color: #4E569E;
    font-weight: 600;
    font-size: 15px;
  }

  }

  .pages2 {

width: 20%;
margin-top: 4%;
a{
font-size: 14px;
font-weight: 500;
letter-spacing: 0.3px;
text-decoration: none;
color: #656565;
width: 100%;
text-align: left;
line-height: 35px;

}
p{
text-align: left;
color: #4E569E;
font-weight: 600;
font-size: 15px;
}
}
.pages3 {

width: 25%;
margin-top: 4%;
display: inline-flex;

.quiklinks{
text-align: left;
color: #4E569E;
font-weight: 500;
font-size: 14px;
}

.covid{
text-align: left;
color: #E75815;
font-weight: 500;
font-size: 14px;
width: 70%;
}


.socialmedia{

  width: 50%;
    display: flex;
    margin-left: 2%;
  
  img{
    width: 40px;
    margin: 0% 2%;
  }
}
}
  
  img{
    width: 75px;
   
    
  }

  .pages1{
    width: 20%;

    img{
    width: 75px;
    margin: auto 0%;
    display: block;
    
  }
  }

  @media screen and (max-width:1200px){
 

    .pages , .pages2 {
      width: 22%;
      font-size: 13px;
    }

    .pages2{
      margin-top: 5.5%;
    }
    .pages3{
      width: 35%;
      margin-top: 5.5%;
      font-size: 13px;
    }
  }

  @media screen and (max-width: 1000px){

display: block;
padding: 1% 5% 1% 5%;

div{
  margin: 5% 0%;
}

.pages , .pages2{
  width: 50%;
  display: inline-block;
}

.pages3{
  width: 55%;
    float: left;

  .covid{
    width: 100%;
  }
}
a{
  text-align: center;
  font-size: 15px;
  width: 100%;
}
img{
width: 55px;
padding-left: 0%;
}

.pages1{
width: 100%;

img{
  margin: auto;
  display: block;
}
}
}

  

  @media screen and (max-width: 600px){

    display: block;
    padding: 1% 5% 1% 5%;

    div{
      margin: 5% 0%;
    }

    .pages , .pages2{
      width: 50%;
      display: inline-block;
    }

    .pages3{
      width: 100%;

      .covid{
        width: 100%;
      }
    }
    a{
      text-align: center;
      font-size: 15px;
      width: 100%;
    }
    img{
    width: 55px;
    padding-left: 0%;
  }

  .pages1{
    width: 100%;

    img{
      margin: auto;
      display: block;
    }
  }
  }
`

function Footer(props) {
  return (
    <>
      <FooterDiv>
        <Subfooterdiv>
          <div className="pages1">
          <Footerlink to="/">
          <img src={Footerlogo}></img>
          </Footerlink>
          <a className="supportmail" href="mailto:support@dezal.in">support@dezal.in</a>
          </div>
          
          <div className="pages">
            <p className="quiklinks">Quick Links</p>
            <Footerlink to="/about_us">About <img  src={logo}/></Footerlink>
            <Footerlink to="/contact_us">Contact Us</Footerlink>
            <Footerlink to="/ourservices">Our Service</Footerlink>
            <Footerlink to="/privacy_policy">Privacy Policy</Footerlink>
            <Footerlink to="/shipping_policy">Shipping Policy</Footerlink>
          </div>
          <div className="pages2">
           <Footerlink to="/faq">FAQ's</Footerlink>
          <Footerlink to="/career">Career</Footerlink>
          <Footerlink to="/terms_conditions">Terms and Conditions</Footerlink>
            <Footerlink to="/refund_policy">Cancellation and Refund</Footerlink>
          </div>
          <div className="pages3">
            <div>
            <p className="quiklinks">Find us on social media</p>
            <Footerlink to="/covid-guidlines">
            <p className="covid">Let us help you in COVID 19</p>
            </Footerlink>
            
            </div>
           
            <div className="socialmedia">
              <Footerlink to="#">
              <img src={Instagram} />
              </Footerlink>
              <Footerlink to="#">
              <img src={Facebook} />
              </Footerlink>
              <Footerlink to="#">
              <img src={Twitter} />
              </Footerlink>
            </div>
            
          </div>
          
         
        </Subfooterdiv>
        <hr/>
        <small> © 2021, Copyright Reserved </small>{" "}
        
      </FooterDiv>{" "}
    </>
  );
}

export default Footer;
