import React from "react";
import styled from "styled-components";
import Background from "../../assets/images/legal/dezal_back.svg"
import termsback from "../../assets/images/legal/refund.svg";
import {Helmet} from "react-helmet";

function refund({Heading,Con1,Introhead1,Introhead2,con2,con3,con4,con5,con6,con7,con8,con9,con10,con11,}) {
    return(
        <>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Cancellation & Refund Policy | Dezal.in </title>
                <link rel="canonical" href="http://dezal.in/refund_policy" />
            </Helmet>
        <Termback >
            <h1>{Heading}</h1>
            <img src={termsback}></img>
        </Termback> 
        <Fulldetail>
        <Subhead>{Introhead2}</Subhead>
            <Para1>{con11}</Para1>
            <Para1>{Con1}</Para1>
            <Para1>{con2}</Para1>           
            <Para1>{con3}</Para1>
            <Para1>{con4}</Para1>
            

            <Subhead>{Introhead1}</Subhead>
            <Para1>{con5}</Para1>
            <Para1>{con6}</Para1>
            <Para1>{con7}</Para1>
            <Para1>{con8}</Para1>
            <Para1>{con9}</Para1>
            <Para1>{con10}</Para1>
            
        </Fulldetail>
        </>
)}


const Termback = styled.div`
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1% 3%;
    color: white;
    display: flex;

    h1{
        font-size: 40px;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding: 0% 8%;
    width: 60%;
    margin: auto 0%;
    }

    @media screen and (max-width:1040px){
        h1{
            font-size: 40px;
        }
        img{
            width: 15%;
        }
    }

    @media screen and (max-width:800px){
        h1{
            font-size: 30px;
        }
    }

    @media screen and (max-width:650px){
        display: block;

        h1{
            font-size: 25px;
    width: 100%;
    padding: 2% 0%;
    text-align: center;
        }
        img{
            margin: 4% auto;
    display: block;
    width: 25%;
        }
    }

`;

const Fulldetail = styled.div`
background-color: #ffffff;
    width: 80%;
    margin: 3% auto;
    text-align: center;
    letter-spacing: 0.3px;
    
`

const Para = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;

    @media screen and (max-width:650px){
        font-size: 12px;
    text-align: justify;
    }
`

const Para1 = styled.p`
color: #6B6B6B;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    text-align: left;

    @media screen and (max-width:650px){
        font-size: 12px;
    }
`

const Subhead = styled.h2`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`

const Subhead1 = styled.h6`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 0.3px;
    margin: 0%;
`

const Myorder = styled.ol`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
`
const Mylist = styled.li`
color: #545BA1;
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;

    @media screen and (max-width:650px){
        font-size: 12px;
    line-height: 25px;
    }
`


export default refund;
