import React from "react";
import Privacy from "../../components/privacy/privacy";

function privacypolicy() {
  return (
    <>
      <Privacy
        Heading="Privacy Policy"
        Title1=" here in after referred as “The Company” or “Company” is to provide and operate Mobile Fuel Dispenser for the purpose of Door Delivery of the petroleum products to Customers using a technology platform (A mobile or Web Application) with systems and standard operating procedures in place. The Company recognizes the importance of maintaining the privacy of the information belonging to you. This Policy sets out details of how we treat user information we collect on www.dezal.in (“The Website”). The Website is owned and operated by the company, having its registered office at at H.No. 31-3-1813, Plot No. 24/A, PARIMALA Colony, Veera NGOs Colony, Warangal, Telangana, India, 506370. By visiting and/or using the Website, you agree to the terms of this Privacy Policy and it shall be deemed that you have read in full, accepted and agreed to the terms herein. Company reserves the right to modify the terms contained in this Privacy Policy at any time by posting such modifications on the Website. The modified Privacy Policy would be effective from the date of modification that is posted by Company on the Website. If you do not agree to any term that is contained in this Privacy Policy, please immediately cease your use of the Website. This Privacy Policy is issued pursuant to the provisions that are contained in (a) Section 43A of the Information Technology Act, 2000; (b) Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011; and (c) Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011."
        Introhead="Information that is collected by Company on the Website shall be used by Company only for the purpose of providing the Services, and such information shall not be used, sold, transferred or rented in a manner that is not set out in this Privacy Policy. Information that is collected by Company:"
        Con1="In providing the various services that are available on the Website (the “Services”), Company may have to collect certain personal and professional information from you. This information would, inter alia, include the following:"
        Introhead1="Contact information:"
        span="M/s GRIT DEZAL ECOMM PRIVATE LIMITED"
        con2="Company may collect your name, email, mobile number, postal address, IP address etc."
        Introhead2="Payment and billing information:"
        con3="Company may collect your billing name, billing address and payment method when you pay the Charges (as defined in the Terms of Use available here www.dezal.in) on the Website. Please note that Company’s online payment partner would collect your credit/debit card number or credit/debit card expiry date or other details pertaining to your credit/debit card. "
        Introhead3="Other information:"
        con4="While you use the Website, Company may collect information about your IP address and the browser you are using. You acknowledge and agree that any and all information that is provided by you on the Website is provided by you voluntarily and at your own risk. While Company would make all efforts to ensure the safety and security of the information provided for you, it shall not be responsible for any loss or theft of data. Please note that any information that is provided by you may be retained by Company for (i) providing the Services; and (ii) for meeting its obligations under applicable law. Even if you delete your User account on Website, Company may have to retain the information provided by you for a short period of time until the same can be removed from its servers, and for compliance with its obligations under law (if any)."
        con5="You shall be responsible for the accuracy of the information provided to Company for the use of the Website and the Services. If there is any mistake in or change to the personal information provided by you for the use of Website, you shall immediately notify Company at support@dezal.in. You shall be solely liable for the consequences of providing incomplete, inaccurate or incorrect information on the Website; Where Company has reasons to believe that the information provided by you on the Website is incorrect or incomplete. In such case Company reserves the right to restrict you from using the Website by disabling your User Account. If you want to discontinue the use of the Website, please notify Company at support@dezal.in. Once you discontinue the use of the Website, Company shall retain the personal information provided by you on the Website for such time period as may be mandated by applicable law. Information may be collected in several ways:"
        Introhead4="Direct collection:"
        con6="This refers to the information that is voluntarily provided by you while registering on the Website, while placing an order for the procurement of the Fuel and so on etc."
        Introhead5="Indirect Collection:"
        con7="This refers to the information that is gathered by Company using tracking tools like Google Analytics, Google Webmaster, browser cookies etc. for collecting information about your usage of our website. This information may be used by the Company for internal research to understand User demographics, interests and behaviour with a view to improving the functionality of the Website. This information may include the URL of the website that you visited just before visiting the Website or which one you go to after visiting the Website, your computer browser information, and your IP address. While Company does not actively collect any information from persons who visit the Website, but do not register themselves on it or avail of the Services, certain portions of this Privacy Policy would be applicable to such visitors as well."
        con8="Use of your personal information by Company:"
        con9="To contact you: The contact information you provide would be used by Company to contact you for the confirmation of an order or for any other Service."
        con10="To improve the quality of the Services: Company might use your information to improve the functionality of the Services and to ensure that its quality is maintained / improved. "
        con11="For security purposes: Company might use the information provided by you on the Website to protect our company, our customers, or our websites."
        con12="For marketing purposes: Company might send you information about special promotions or offers. Company might also notify you of new or improved features on the Website."
        con13="Transactional communications: Company may also contact you by way of emails or SMSs to confirm an order, confirm the payment of Charges etc. Company shall use your information only in a manner that is permitted by applicable law."
        con14="Sharing of information by Company with third-parties:"
        con15="Service Providers: Company shall share your information with persons who Company has contracted or appointed for assistance with performing the Services such as delivery facilitators, operators, partners, payment processors or transactional message processors."
        con16="Compliance with law: Company might share your information to respond to a court order or subpoena. Company may share your information if a government agency or investigatory body requests Company to do so."
        con17="Mergers and Acquisitions: If Company sells its business or hives of a portion of the same to a new entity, your personal information that is available on the Website may be transferred to the new entity."
        Introhead6="Security of your Information:"
        con18="Please note that neither Company nor any of its employees would have access to the password that relates to your User account on the Website. You shall be solely responsible for protecting your login credentials to the Website, and Company shall not be liable in any manner for unauthorised use of your login credentials. You shall be liable to Company for any loss suffered by Company as a result of unauthorised use of your login credentials. In the event of any misuse or suspected misuse of the same, please notify Company at support@dezal.in."
        con19="While Company shall seek to implement the best market practices and security policies for the protection of your personal information, it shall not be liable for theft or loss of data due to unauthorised access to the User’s electronic devices."
        Introhead7="Cookies:"
        con20="A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you grant the permission, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Company uses traffic log cookies to identify which pages are being used. This helps Company analyse data about web page traffic and improve the functioning of the Website. This information is used by Company only for statistical analysis and then the data is removed from the system. Please note that Company does not have any control over the cookies that are placed by third party links on the Website. Company does not represent to you that such third party websites have their own Privacy Policies or that the information that is provided by you to them would be secure."
        Introhead8="THIRD PARTY SITES"
        con21="If you click on one of the links to third party websites that may be available on the Website, you may be taken to websites that Company does not control. This Privacy Policy does not apply to the privacy practices of those websites. Company shall not be liable for the misuse of your personal information by third party websites."
        Introhead9="REDRESSAL OF GRIEVANCES"
        con22="If a User has any questions or grievances regarding the Website, the contents thereof or the Services, the User may reach out to customer support at email address support@dezal.in (the “Grievance Officer”). The Grievance Officer shall address any complaint or grievance that is raised a User within a maximum period of 15 working days from when it is raised."
        Introhead10="MISCELLANEOUS PROVISIONS"
        Introhead11="Force Majeure:"
        con23="Company shall not responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of God, war, terrorism, armed conflict, labor strike, lockout, boycott or any uncontrollable circumstances. Company may at any time, in its sole discretion and without advance notice to you, cease operation of the Website and the provision of the Services."
        Introhead12="No Waiver:"
        con24="A delay or failure by Company to exercise or enforce any right or provision of this Privacy Policy will not constitute a waiver of such right or provision."
        Introhead13="Notices:"
        con25="All notices given to a User by Company or by a User to Company or another User shall be in writing and in the English language. Such notice shall be sent by e-mail or mailed by a prepaid internationally-recognized courier service to the intended recipient at the address set out below, or any changed address that is notified by either Party."
        Introhead14="Notice to Company:"
        con26="M/s GRIT DEZAL ECOMM PRIVATE LIMITED"
        con27="Address H.No. 31-3-1813, Plot No. 24/A, PARIMALA Colony, Veera NGOs Colony, Warangal, Telangana, India, 506370."
        con28="Email: support@dezal.in"
        Introhead15="Notice to User:"
        con29="At the email address provided by you at the time of registration on the Website. Governing Law Jurisdiction: This Privacy Policy is governed by the provisions of Indian law, including but not limited to the following:"
        con30="1. the Indian Contract Act, 1872; "
        con31="2. the (Indian) Information Technology Act, 2000;"
        con32="3. the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011;"
        con33="4. The (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011. "
        con34="This Website originates from the State of Telangana in India. This Privacy Policy will be governed by the laws that are applicable in the State of Telangana. By using this Website, you consent to the jurisdiction and venue of the courts located in Telangana, India in connection with any action, suit, proceeding or claim arising under or by reason of this Privacy Policy."
      />
    </>
  );
}

export default privacypolicy;
