import React from "react";
import AboutDiselCard from "./AboutCard";
import styled from "styled-components";
import { Title } from "../../styledComponent/styled";
import logo from "../../assets/images/logo/dezal-written-logo.svg";
import c1_1 from "../../assets/images/image/C1-11.svg";
import c1_2 from "../../assets/images/image/C1-21.svg";
import c1_3 from "../../assets/images/image/C1-31.svg";

const Container = styled.div`
  background: #f9fbfb 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 44px;
`;
const SubContainer = styled.div`
  width: 86.92%;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;
const NewTitle = styled(Title)`
  margin-top: 44px;
  margin-bottom: 38px;
`;

const AboutDezal = () => {
  return (
    <Container>
      <NewTitle>
        What Sets <img src={logo} /> Apart
      </NewTitle>
      <SubContainer>
        <AboutDiselCard
          logo={c1_3}
          title={"Planning"}
          para="Your entire month’s fuel requirement is planned just in a click. All you need to do is just go through our dezal app, fill in the order details for the day and we’ll take care of the rest!"
        />
        <AboutDiselCard
          logo={c1_2}
          title={"Control"}
          para="Say “NO” to pilferage and wastage of fuel; instead “SAVE” fuel. Track your order online, get delivery at your place and in your presence. Our dezal app gives you monitoring and control on your fuel purchase process. Your order is under your control! So now you don’t have to worry about theft, spillage or compromise in the quality and quantity of the diesel. Stay tension free and track your order from purchase to delivery."
        />
        <AboutDiselCard
          logo={c1_1}
          title={"Convenience"}
          para="Easy ordering and hassle-free doorstep delivery at preferred time makes your life easier. dezal App ensures your fuel purchase process in a structured in a Convenient way. It saves your time, you no longer have to travel any distances to purchase diesel for your needs. It saves your money, manpower and any additional cost for storage and handling of diesel. We will ensure hassle free delivery once the order is placed."
        />
      </SubContainer>
    </Container>
  );
};

export default AboutDezal;
