import React from "react";
import styled from "styled-components";
import Head from "../components/ourservices/head";
import Agriculture from "../components/ourservices/agriculture";
import Construction from "../components/ourservices/construction";
import Power from "../components/ourservices/power";
import Mining from "../components/ourservices/mining";
import Industries from "../components/ourservices/industries";
import Downloadapp from "../components/downloadApp/DownloadApp";
import { Helmet } from "react-helmet";

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const aboutUs = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Services | Dezal.in </title>
        <link rel="canonical" href="http://dezal.in/ourservices" />
      </Helmet>
      <Head />
      <Agriculture />
      <Construction />
      <Power />
      <Mining />
      <Industries />
      <Container>
        <Downloadapp marginTop="5%" marginBottom="5%" />
      </Container>
    </div>
  );
};

export default aboutUs;
