import React from "react";
import styled from "styled-components";

const Title = styled.p`
  font: normal normal 600 29px Poppins;
  letter-spacing: 0px;
  color: #0d0e50;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 5%;
  width: 85%;
  margin-left: 10%;
  @media screen and (max-width: 580px) {
    font-size: 20px;
    margin-left: 5%;
  }
`;

const Subtitle = styled.p`
  text-align: left;
  font: normal normal 600 20px/55px Poppins;
  letter-spacing: 0px;
  color: #0d0e50;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 2%;
  line-height: 35px;
  border-bottom: 2px solid #0d0e50;
  margin-left: 10%;
  margin-bottom: 0%;
  width: 20%;

  @media screen and (max-width: 1100px) {
    text-align: left;
    font: normal normal 600 20px/55px Poppins;
    line-height: 35px;
    width: 30%;
  }

  @media screen and (max-width: 800px) {
    width: 40%;
  }

  @media screen and (max-width: 650px) {
    text-align: left;
    font: normal normal 600 15px/55px Poppins;
    margin-left: 5%;
    width: 50%;
    line-height: 30px;
  }
`;

const Head = () => {
  return (
    <>
      <Title>Our Services</Title>
      <Subtitle>We Serve Below Industries</Subtitle>
    </>
  );
};

export default Head;
