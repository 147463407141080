import styled from "styled-components";

export const Title = styled.p`
  font: normal normal 600 29px Poppins;
  letter-spacing: 0px;
  color: #0d0e50;
  text-transform: capitalize;
  opacity: 1;
  margin-bottom: 0px;
  margin-top: 10%;

  @media screen and (max-width: 650px) {
    font: normal normal 600 20px Poppins;

    img {
      width: 62px;
    }
  }
`;
