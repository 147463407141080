import React from "react";
import styled from "styled-components";
import deselLogo from "../../assets/images/background/truck.svg";
import { Title } from "../../styledComponent/styled";
const Container = styled.div`
  width: 86.92%;
  display: flex;
  height: 246px;
  justify-content: space-between;
  margin-top: 2%;
  /* border: 1px solid red; */

  @media screen and (max-width: 980px) {
    display: block;
    height: 0%;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60.21%;

  @media screen and (max-width: 980px) {
    display: block;
    width: 100%;
  }
`;
// const Title = styled.p`
//   font: normal normal 600 29px Poppins;
//   letter-spacing: 0px;
//   color: #0d0e50;
//   text-transform: capitalize;
//   opacity: 1;
//   margin-bottom: 0px;
//   margin-top: 0px;
// `;
const Details = styled.p`
  text-align: left;
  font: normal normal medium 16px Poppins;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.08px;
  color: #525252;
  opacity: 1;

  height: 174px;

  @media screen and (max-width: 980px) {
    height: 95px;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    height: 0%;
    font-size: 13px;
  } ;
`;
const NewTitle = styled(Title)`
  margin-top: 31px;
`;

const Imagediv = styled.div`
  margin-top: 8%;

  @media screen and (max-width: 1200px) {
    margin-top: 16%;
  }

  @media screen and (max-width: 980px) {
    img {
      margin: auto;
      display: block;
    }
  }

  @media screen and (max-width: 800px) {
    margin-top: 12%;

    img {
      margin: auto;
      display: block;
    }
  }

  @media screen and (max-width: 650px) {
    img {
      width: 80%;
    }
  }
`;

const WhoWeAre = () => {
  return (
    <Container>
      <ContentWrapper>
        <NewTitle>Who We Are</NewTitle>
        <Details>
          We are into Fuel Marketing and Delivery & Logistics business. We
          primarily deal with High-Speed Diesel (commonly known as ‘diesel’) by
          taking orders from high volume diesel customers (Esp. Agriculture, DG
          sets, Earth moving/ Mining equipment’s, Construction/Infra, Industries
          and others etc..).
        </Details>
        <Details>
          Our dezal app enable customers to place orders online for diesel and
          gives control to monitor the delivery process. Customers will also
          have the convenience to access the completed orders & invoices. Our
          Mobile Dispensers (Specially Designed Vehicles to carry fuel, as per
          guidelines by Petroleum Explosives and Safety Organization - PESO) are
          equipped with state of art technological devices and are approved by
          PESO & calibrated by Legal Metrology Department.
        </Details>
      </ContentWrapper>
      <Imagediv>
        <img src={deselLogo} />
      </Imagediv>
    </Container>
  );
};

export default WhoWeAre;
