import React from "react";
import styled from "styled-components";
import WhoWeAre from "../components/aboutUs/WhoWeAre";
import MissionVision from "../components/aboutUs/MissionVision";
import DownloadApp from "../components/downloadApp/DownloadApp";
import {Helmet} from "react-helmet";

const Container = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  flex-direction: column;
`;
const RowWrapper = styled.div`
  width: 100%;
  margin-bottom: 110px;
  display: flex;
  justify-content: center;


  @media screen and (max-width:800px){
    margin-bottom: 0%;
  }
`;

const aboutUs = () => {
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | Dezal.in </title>
                <link rel="canonical" href="http://dezal.in/about_us" />
            </Helmet>
    <Container>
      <WhoWeAre />
      <MissionVision />
      <RowWrapper>
        <DownloadApp />
      </RowWrapper>
    </Container>
    </>
  );
};

export default aboutUs;
