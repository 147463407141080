import React from "react";
import Terms from "../../components/shipping/shipping_address";

function Shipping() {
  return (
    <>
      <Terms
        Heading="Shipping Policy"
        Title1=" A Shipping Policy lets customers know details about how to get fuel delivered to their locations. "
        Con1='Following are the terms and conditions that constitute our shipping policy:'
       con2="Firstly, customer places an order using The Company’s website/ mobile application. The Company will notify the customer about the order confirmation. "
        con3="Dispatches are scheduled as per the received orders from different customers and the customers are notified once the Mobile dispenser is dispatched for delivery as per the schedule."
        con4=" The Company does endeavor to deliver the fuel within the agreed appointment window but not guarantee delivery at any particular time within such window and does not guarantee that The Company’s mobile dispenser will arrive within the estimated time. The customer acknowledge and agrees that this delivery window is an estimate only."
        con5="Once The Company’s mobile dispenser arrives onsite, whether within or outside the estimated window, the driver will notify the customer via Company’s application, and the Company’s driver will wait no more than fifteen minutes for the customer to give access to the storage container of the customer’s choosing for transferring the Fuel procured. If access not granted within such fifteen minutes period, The Company’s mobile dispenser will leave the customer’s location and the customer will be charged applicable fees irrespective of whether the delivery was successful."
        con6="Due to reasons such as restrictions imposed by applicable law, terrain or building features and limitations (e.g. overhead clearance), or the requirements of some private property owners, the services may not be available in all locations within a service area. We reserve the right to decline to enter into any area based on ease of access or for any other reason known to the company. The Company shall not be charged in such an event and cancellation of such orders will be processed as per the Company’s cancellation policy. "
        con7=" Due to any reason if the Mobile Dispenser is late or not able to reach the customer specified location we will endeavor to complete the delivery on the same day or by the next day. The Company shall not be charged in such an event and cancellation, if any, of such orders will be processed as per the Company’s cancellation policy."
        con8="HSD means High Speed Diesel or Diesel confirming IS specification IS 1460-2005."
        con9="Upon successful delivery as per the procedure the order will be closed and the details updated in the application."
        
      />
    </>
  );
}

export default Shipping;
