import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  text-decoration: none !important;
  font: normal normal medium 16px/30px Poppins;
  font-weight: 500;
  margin-bottom: 17px;
  letter-spacing: 0px;
  color: #2c2c2c;
  opacity: 1;
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffede4;
  opacity: 0.95;
  position: fixed;
  padding-top: 20px;
  display: none;
  padding-bottom: 10px;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  @media screen and (max-width: 580px) {
    display: flex;
  }
`;

const MobileNavbar = ({ openMobileNav }) => {
  return (
    <Container onClick={() => openMobileNav()}>
      <StyledLink to="/">Home</StyledLink>
      <StyledLink to="/about_us">About Us</StyledLink>
      <StyledLink to="/ourservices">Our Services</StyledLink>
      <StyledLink to="/career">Career</StyledLink>
      <StyledLink to="/faq">FAQs</StyledLink>
      <StyledLink to="/contact_us">Contact Us</StyledLink>
     
    </Container>
  );
};

export default MobileNavbar;
