import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/popup/logotm.png";
import background from "../../assets/images/popup/truck.png";
import cross from "../../assets/images/popup/cross.svg";

const PopupMainDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContentDiv = styled.div`
  background-color: white;
  height: 45%;
  width: 40%;
  padding: 1% 2%;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  background-size: cover;

  .cross {
    float: right;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .left {
    padding: 10% 7%;

    .comingsoon {
      font-size: 40px;
      color: #f06725;
      font-weight: 500;
      margin: 0%;
    }
    .logoimage {
      width: 10%;
    }
  }

  @media (max-width: 600px) {
    background-color: white;
    height: 20%;
    width: 90%;
    background-size: cover;
    border-radius: 10px;

    .comingsoon {
      font-size: 25px !important;
    }
  }
`;

function Popup(props) {
  return props.trigger ? (
    <PopupMainDiv>
      <PopupContentDiv>
        <button className="cross" onClick={props.closePopup}>
          <img src={cross} />
        </button>
        <div>
          <div className="left">
            <img className="logoimage" src={logo}></img>
            <p className="comingsoon">Coming Soon !</p>
          </div>
          <div></div>
        </div>
      </PopupContentDiv>
    </PopupMainDiv>
  ) : (
    ""
  );
}

export default Popup;
