import React from "react";
import styled from "styled-components";
import puzzleCard from "../../assets/images/image/puzzlecard1.png";

const Container = styled.div`
  width: 86.92%;
  height: 403px;
  background: #fe7d1e 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  background-image: url(${puzzleCard});
  background-size: 352px, 384px;
  background-position-x: 98%;
  background-position-y: 30px;
  margin-bottom: 127px;
  @media screen and (max-width: 1066px) {
    height: auto;
  }
  @media screen and (max-width: 580px) {
    background-position-y: 20px;
    background-position-x: 45%;
    height: auto;
    background-size: 205px, 224px;
    margin-bottom: 30px;
  }
`;
const ContentDiv = styled.div`
  width: 61%;
  height: auto;
  margin-left: 22px;
  margin-top: 42px;
  @media screen and (max-width: 580px) {
    margin-top: 220px;
    width: 93%;
  }
`;
const Paragraph = styled.p`
  font-weight: 500;
  font-size: 16px;
  font-family: Poppins;
  letter-spacing: 0.08px;
  color: #ffffff;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (max-width: 580px) {
    font-size: 14px;
  }
`;
const SupportPara = styled.p`
  text-align: left;
  font: normal normal 600 23px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  @media screen and (max-width: 580px) {
    font-size: 16px;
  }
`;

const careerCard = () => {
  return (
    <Container>
      <ContentDiv>
        <Paragraph>
          Are you Customer obsessed, relentlessly focused on achieving high
          standards, Field-smart and analytical, Focused on execution, hungry
          and passionate about e-commerce, an experienced sales executive with a
          strong work ethic? If yes, then Team dezal is waiting for you.
        </Paragraph>
        <Paragraph>
          The ideal candidate will have Ability to work in an environment with
          minimal supervision, comfortable working across multiple complex
          functions Ability to work in a dynamic environment and should be adept
          at dealing with ambiguous, new and challenging situations Field sales
          & marketing experience or interested in Field Sales & Marketing
        </Paragraph>
        <SupportPara marginBottom="0">
          Send us your resume at support@dezal.in,
        </SupportPara>
        <SupportPara marginTop="0">
          Our HR will get back with the right opportunity for you.
        </SupportPara>
      </ContentDiv>
    </Container>
  );
};

export default careerCard;
