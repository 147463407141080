import React from "react";
import styled from "styled-components";
import MissionVisionCard from "./MissionVisionCard";

const Container = styled.div`
  width: 86.92%;
  display: flex;
  justify-content: space-between;
  margin-top: 15%;
  margin-bottom: 130px;


  @media screen and (max-width:1200px){
    margin-top: 30%;
  }

  @media screen and (max-width:945px){
    margin-top: 5%;
    display: block;
    width : 100%;
  }

  @media screen and (max-width:800px){
    margin: 5%;
  }


  @media screen and (max-width:600px){
    margin: 10% 0%;
   
  }
`;

const MissionVision = () => {
  return (
    <Container>
      <MissionVisionCard
        title={"Our Mission"}
        content={
          "Our mission is to provide Planning, Control & Convenience and enhance customer experience by optimization of safe processes, efforts and resources in “fuel purchase” through our latest digital technology interface."
        }
      />
      <MissionVisionCard
        bgColor={"#FFF0DA"}
        title={"Our Vision"}
        content={
          "To become a preferred fuel-marketing channel partner to the Oil Marketing companies, Petroleum Dealers and Customers in Petroleum Industry."
        }
      />
    </Container>
  );
};

export default MissionVision;
