import React from "react";
import styled from "styled-components";
import logo from "../assets/images/logo/dezal-written-logo.svg";
import CareerCard from "../components/career/careerCard";
import DownloadApp from "../components/downloadApp/DownloadApp";
import { Title } from "../styledComponent/styled";
import { Helmet } from "react-helmet";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const NewTitle = styled(Title)`
  width: 86.92%;
  margin-bottom: 30px;
  margin-top: 65px;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
  }
`;

const career = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers | Dezal.in </title>
        <link rel="canonical" href="http://dezal.in/career" />
      </Helmet>
      <Container>
        <NewTitle>
          Careers At <img src={logo} />
        </NewTitle>
        <CareerCard />
        <DownloadApp marginBottom="100px" />
      </Container>
    </>
  );
};

export default career;
