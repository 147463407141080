import React from "react";
import styled from "styled-components";
import Servicecards from "../ourservices/servicecards";
import Agri1 from "../../assets/images/ourservices/agri1.svg";
import Agri2 from "../../assets/images/ourservices/agri2.svg";
import Agri3 from "../../assets/images/ourservices/agri3.svg";




const ServiceHeading = styled.p`
text-align: left;
    font: normal normal normal 20px/50px Poppins;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0.3px;
    color: #545BA1;
    text-transform: capitalize;
    font-weight: 500;
    opacity: 1;
    margin-left: 10%;
    margin-top: 0%;


@media screen and (max-width:650px){
    text-align: left;
    font: normal normal normal 13px/55px Poppins;
    margin-left: 5%;
    margin-top: 5%;
    line-height: 20px;
}
`

const Servicedescription = styled.p`
text-align: left;
font: normal normal normal 14px/55px Poppins;
letter-spacing: 0px;
color: #525252;
text-transform: capitalize;
opacity: 1;
margin-left: 10%;
margin-top: 0%;

@media screen and (max-width:800px){
    text-align: left;
    font: normal normal normal 13px/55px Poppins;
    width: 80%;
    line-height: 25px;
}


@media screen and (max-width:650px){
    text-align: left;
    width: 90%;
   margin-left: 5%;
    line-height: 25px;
}
`



const Agriculture = () => {
    return(
       <>
        <ServiceHeading>Agriculture</ServiceHeading>
        <Servicecards image1 = {Agri1} image2 = {Agri2} image3 = {Agri3}/>
        {/* <Servicedescription>We Keep Your Farm Moving By Keeping Your Agricultural Equipment’s (Like Farm Tractors) Fueled.</Servicedescription> */}
       </>
    );
};

export default Agriculture;