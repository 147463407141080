import React, { useState } from "react";
import styled, { css } from "styled-components";
import DownArrow from "../../assets/images/logo/downArrow.svg";
import UpArrow from "../../assets/images/logo/upArrow.svg";

const Container = styled.div`
  width: 100%;
  max-height: ${(props) => (props.open ? "500px" : "38px")};
  transition: max-height 0.5s ease-in;
  min-height: 38px;
  background: #f5f6f9 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding-left: 19px;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
  background-image: url(${(props) => props.arrow});
  background-position-x: 98%;
  background-position-y: 25px;
  margin-bottom: 12px;
  cursor: pointer;

  /* justify-content: center; */
  @media screen and (max-width: 580px) {
    width: 95%;
    padding-right: 10px;
    padding-left: 10px;
  }
`;
const Question = styled.p`
  font: normal normal medium 14px Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #5d6066;
  text-transform: capitalize;
  opacity: 1;
  @media screen and (max-width: 580px) {
    font-size: 11px;
  }
`;
const Answer = styled.p`
  font: normal normal normal 12px/22px Poppins;
  letter-spacing: 0px;
  color: #797979;
  opacity: 1;
  display: ${(props) => (props.open ? "block" : "none")};
`;

const QuestionCard = ({ q, a }) => {
  const [openAns, setOpenAns] = useState(false);
  const handleOpenAns = () => {
    setOpenAns(!openAns);
  };
  console.log(openAns);
  return (
    <Container
      onClick={handleOpenAns}
      open={openAns}
      arrow={openAns ? UpArrow : DownArrow}
    >
      <Question>{q}</Question>
      <Answer open={openAns}>{a}</Answer>
    </Container>
  );
};

export default QuestionCard;
