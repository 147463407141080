import React from "react";
import Refund from "../../components/refundpolicy/refund";

function refund() {
  return (
    <>
      <Refund
        Heading="Cancellation & Refund Policy"
        Introhead2="Refund Policy"
        con11="Once  we  receive  your request  for  cancellation  and  refund,  we  will  scrutinize  and evaluate the transaction, after approvalonlyrefund will initiate. The refund amount will  be  calculated  by  deducting  Internet  payment  charges  from  your original payment."
        Con1="You will receive the credit within a certain amount of days,depending on your card issuer’spolicies.  Refunds  will  be  issued  to  the  same  payment method,  whichwas used at the time of purchase."
        con2="1. Debit card/ Credit Card –5 to 7 working days"
        con3="2. Net Banking –3 to 5 working days"
        con4="If  the  standard  time-frame  as  mentioned above  has  passed  and  you have  still  not received the refund, please contact your credit or debit card issuer or your bank for more information."
        con5="If you have any questions about our Returns and Refunds Policy, please contact us by email at support@dezal.in."
        Introhead1="Cancellation Policy"
        con5="1. Cancellation  before  Dispatchof  the  product: In  case the  Order  is  canceled before dispatch, no cancellation charges shall be leviable but in case the same is canceled thereafter then a cancellation fee shall be payable by the userto the Company."
        con6="Customer  reserves the  rightsto  cancel  the  order  before  the  product  is dispatched."
        con7="2. Cancellation after Dispatch of the product: In case ofpost confirmation of the order, if the order gets delayed or cancelleddue to any reason,then Companyshall not be responsible to any partyfor cancellation of the order or any kind of delay charges or compensationfor the supply in any eventuality."
        con8="Cancellation of such orders will be processed on case to case basis, however the company reserves the right of final say."
        con9="3. In case of the Operator fails to comply with or adhere to any of the applicable rules, etc.then  the usershall  be  within  its  right  to cancel  the  order immediately,  but  before  the  start  of  delivery  process,without  any  further charges."
        con10="4. In case of Force MajeureSituation: Cancellation at any stage is not allowed and the company. Cancellation  of  such  orders  will  be  processed on case  to case basis, however the company reserves the right of final say."
      />
    </>
  );
}

export default refund;
