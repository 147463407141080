import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import herobackgroundone from "../../assets/images/background/heroone.svg";
import herobackgroundtwo from "../../assets/images/background/herotwo.png";
import herobackgroundonemob from "../../assets/images/background/mob.png";
import dezalLogo from "../../assets/images/logo/logotm.png";

/*

  Hero section of the website

*/

const HeroDiv = styled.div`
  @media (max-width: 800px) {
    background-image: none;
  }
  background-image: url(${herobackgroundone});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${"" /* height: 90vh; */}
`;

const D = styled.span`
  color: #4e569e !important;
`;

const MainHeading = styled.p`
  font-size: 60px;
  font-weight: bold;
  color: #db4146;
  font-family: "Rubik", sans-serif;
  margin: 1% 0% 1% 0%;

  @media (max-width: 800px) {
    font-size: 30px;
    font-weight: bold;
    color: #db4146;
    font-family: "Rubik", sans-serif;
    margin: 23% 0% 0% 0%;
  }

  @media (max-width: 600px) {
    margin: 28% 0% 0% 0%;
  }
`;

const SubHeading = styled.p`
  color: #4e569e;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;

  @media (max-width: 600px) {
    font-size: 18px;
    width: 80%;
  }
`;

const Description = styled.p`
  width: 33%;
  letter-spacing: 0px;
  color: #575757;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.3px;
  opacity: 0.8;
  text-align: justify;

  @media (max-width: 800px) {
    width: 90%;
    color: #575757;
    font-size: 14px;
    line-height: 23px;
  }
`;

const CustomButton = styled.a`
  box-shadow: 0px 15px 30px #7b472e2e;
  opacity: 1;
  background-color: #e66526;
  border-radius: 50px;
  width: 155px;
  color: white;
  padding: 1% 1%;
  font-size: 20px;
  font-weight: 600;
  ${"" /* letter-spacing: 0.3px; */}
  border: none;
  cursor: pointer;
  text-decoration : none;
  margin-top: 3%;
  box-shadow: 0px 15px 30px #ffd6c3c9;
  text-align: center;
    display: block;

  ${
    "" /* @media (max-width:800px){
      box-shadow: 0px 15px 30px #7b472e2e;
    opacity: 1;
    background-color: #E66526;
    border-radius: 50px;
    width: 25%;
    color: white;
    padding: 2% 1%;
    font-size: 20px;
    font-weight: 500;
    }

    @media (max-width: 600px) {
      box-shadow: 0px 15px 30px #7b472e2e;
    opacity: 1;
    background-color: #E66526;
    border-radius: 50px;
    width: 35%;
    color: white;
    padding: 2.5% 1%;
    font-size: 18px;
    font-weight: 500;
    -webkit-letter-spacing: 0.3px;
    -moz-letter-spacing: 0.3px;
    -ms-letter-spacing: 0.3px;
    letter-spacing: 0.3px;
    border: none;
    cursor: pointer;
    margin-top: 3%;
    } */
  }
`;

const SubDiv = styled.div`
  background-image: url(${herobackgroundtwo});
  background-position: 80% 109% ;
  background-size: 45%;
  background-repeat: no-repeat;
  padding-left: 6%;
  padding-top: 2vh;
  padding-bottom: 4vh;

  img {
    width: 10%;
  }

  @media (max-width: 800px) {
    ${'' /* height: 100vh; */}
    background-image: url(${herobackgroundonemob});
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;

    img{
      width:20%;
    }
  }

  @media (max-width: 600px) {
    ${'' /* height: 70vh; */}
    padding-bottom: 0vh;

    img {
      width: 18%;
    }
  }
`;

function Hero(props) {

  const History = useHistory()
  return (
    <>
      <HeroDiv>
        <SubDiv>
          <img src={dezalLogo} alt="" />
          <MainHeading>
            <D>D</D>iesel <D>D</D>oor <D>D</D>elivery{" "}
          </MainHeading>{" "}
          <SubHeading> Make Your Fuel Purchase Process Easier </SubHeading>{" "}
          <Description>
            Our mission is to provide Planning, Control & Convenience and
            enhance our customer experience by optimization of safe processes,
            efforts and resources in “Fuel Purchase” through our latest
            digital technology interface.{" "}
          </Description>{" "}
          <CustomButton  type="button" href="http://dezal.in/" target="_blank">
            {" "}
            Order Now{" "}
          </CustomButton>{" "}
        </SubDiv>
      </HeroDiv>{" "}
    </>
  );
}

export default Hero;
