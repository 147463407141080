import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 86.92%;
`;

const Heading = styled.p`
  text-align: left;
  font: normal normal normal 18px/30px Poppins;
  letter-spacing: 0px;
  color: #707070;
  /* margin-left: 5%; */
  margin-bottom: 0%;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 1100px) {
    width: 90%;
    font: normal normal normal 16px/30px Poppins;
  }

  @media screen and (max-width: 650px) {
    font: normal normal normal 15px/30px Poppins;
  }
`;

const Heading1 = styled.p`
  text-align: left;
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #4E569E;
  /* margin-left: 5%; */
  margin-bottom: 0%;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 1100px) {
    width: 90%;
    font: normal normal normal 16px/30px Poppins;
  }

  @media screen and (max-width: 650px) {
    font: normal normal normal 15px/30px Poppins;
  }
`;

const Contact = styled.div`
margin-top: 4%;
`

const Supportlink = styled.p`
  text-align: left;
  font: normal normal 600 18px Poppins;
  font-weight: 500;
  letter-spacing: 0px;
  color: #545ba1;
  /* margin-left: 5%; */
  margin-top: 0%;
    margin-bottom: 1%;

  a {
    text-decoration: none;
    color: #545ba1;
  }

  @media screen and (max-width: 1100px) {
    width: 90%;
    font: normal normal normal 16px Poppins;
    font-weight: 500;
  }

  @media screen and (max-width: 650px) {
    font: normal normal normal 13px Poppins;
    font-weight: 500;
  }
`;

const Details = () => {
  return (
    <Container>
      <Heading>Please Reach Us At</Heading>
      <Heading1 marginTop="3%">Registered Address : </Heading1>
      <Heading marginTop="0%"> M/s Grit Dezal Ecomm Pvt., Ltd.</Heading>
      <Heading marginTop="0%">
        H.NO 31-3-1813, PLOT NO 24/A, PARIMALA COLONY, VEERA NGOs COLONY,
        WARANGAL, Telangana, India, 506370.
      </Heading>
      <Heading1 marginTop="3%">Operational Address :</Heading1>
      <Heading marginTop="0%"> M/s Grit Dezal Ecomm Pvt., Ltd.</Heading>
      <Heading marginTop="0%">
        H.NO 31-3-1813, PLOT NO 24/A, PARIMALA COLONY, VEERA NGOs COLONY,
        WARANGAL, Telangana, India, 506370.
      </Heading>
      <Contact>
      <Supportlink>
        Contact Us On: <a href="tel:917416742352">+91-7416742352</a>
      </Supportlink>
      <Supportlink>
        Email Us On: <a href="mailto:support@dezal.in">support@dezal.in</a>
      </Supportlink>
      </Contact>
      
    </Container>
  );
};

export default Details;
