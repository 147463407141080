// import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import Navbar from "./components/navbar/navbar";
import Navbar1 from "./components/navbar/navbar1";
import Home from "./screens/home/home";
import Privacypolicy from "./screens/home/privacy";
import Terms from "./screens/home/terms";
import Shipping from "./screens/home/shipping";
import Refund from "./screens/home/refund";
import AboutUs from "./screens/aboutUs";
import Career from "./screens/career";
import Ourservices from "./screens/ourservice";
import Faq from "./screens/faq";
import CovidGuidelines from "./screens/covidGuidelines";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/ScrollToTop";
import Contactus from "./screens/contactus";
import MobileNavbar from "./components/MobileNavbar";

function App() {
  const [mobileNav, setMobileNav] = useState(false);

  const openMobileNav = () => {
    setMobileNav(!mobileNav);
  };

  const handleScroll = () => {
    setMobileNav(false);
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <Router>
      <ScrollToTop />

      <Navbar openMobileNav={openMobileNav} mobileNav={mobileNav} />
      {mobileNav && <MobileNavbar openMobileNav={openMobileNav} />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy_policy" component={Privacypolicy} />
        <Route exact path="/terms_conditions" component={Terms} />
        <Route exact path="/refund_policy" component={Refund} />
        <Route exact path="/shipping_policy" component={Shipping} />
        <Route path="/about_us" component={AboutUs} />
        <Route path="/career" component={Career} />
        <Route path="/ourservices" component={Ourservices} />
        <Route path="/faq" component={Faq} />
        <Route path="/covid-guidlines" component={CovidGuidelines} />
        <Route path="/contact_us" component={Contactus} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
