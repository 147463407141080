import React, { useState } from "react";
import styled from "styled-components";
import Hero from "../../components/hero/hero";
import Popup from "../../components/popup/Popup";
import Downloadapp from "../../components/downloadApp/DownloadApp";
import { Helmet } from "react-helmet";
import AboutDezal from "../../components/aboutDezalCard/AboutDezal";
import Working from "../../components/Working";

function Home(props) {
  const [popupState, setPopupState] = useState(false);

  function openPopup() {
    setPopupState(true);
  }

  function closePopup() {
    setPopupState(false);
  }

  const RowWrapper = styled.div`
    width: 100%;
    margin-bottom: 110px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 800px) {
      margin-bottom: 0%;
    }
  `;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Dezal.in </title>
        <link rel="canonical" href="http://dezal.in/" />
      </Helmet>
      <Hero popup={openPopup} />
      <AboutDezal />
      <Working />
      <RowWrapper>
        <Downloadapp />
      </RowWrapper>

      <Popup trigger={popupState} closePopup={closePopup}></Popup>
    </>
  );
}

export default Home;
